@import "../../../../globals/mixins.scss";

.product-dashboard-buyer {
    width: 100%;

    .block-form-promo {
        background-color: white;
        border-radius: 5px;
        padding: 12px;
        position: relative;
        padding: 2rem;
        width: 400px;

        .close-x {
            position: absolute;
            top: -3%;
            right: 2%;
            font-size: 2rem;
            color: var(--dark);
            cursor: pointer;
        }

        .text {
            margin-top: 1rem;
            width: 90%;
            margin-bottom: 1rem;
        }

        .box {

            p {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                font-size: 13px;

                b {
                    text-transform: capitalize;
                    margin-left: 1rem;
                    font-size: 1rem;
                }

                input {
                    margin-left: 12px;
                    height: 32px;
                    width: 220px;
                    background: none !important;
                    border-radius: 4px;
                    padding: 0 4px 0 4px;
                    border: 1px solid var(--whitesmoke-900) !important;
                    font-size: 14px;
                    margin-right: 5px;
                }
            }

            button {
                background: var(--dark) !important;
                font-size: 14px;
                color: white;
                border-radius: 2px !important;
                border: none;
                height: 32px;
                padding: 0 2rem 0 2rem;
                margin-top: 1rem;
            }
        }

        @include small {
            width: 70%;

            .box {
                p {
                    input {
                        width: 100%;
                    }
                }
            }
        }
    }

    .product-box {
        .btn-add-product {
            width: 100%;
            display: flex;
            margin: 2px 0 1.2rem;

            p {
                margin-left: 1rem;
            }

            button {
                display: flex;
                align-items: center;
                height: 34px;
                font-size: 14px;
                padding: 0 1rem 0 1rem;
                border: none;
                background: var(--colorrs);
                color: white;
                border-radius: 4px;
                box-shadow: 0 10px 10px var(--box-shadow);

                svg {
                    margin-right: 6px;
                }

                &:hover {
                    box-shadow: none;
                }
            }

            @include tablet {
                position: absolute;
                width: 90%;

                p,
                button {
                    margin-bottom: 5rem;
                }
            }
        }

        .list-product {
            width: 100%;

            .btn-generate {
                background: none;
                color: var(--deep-brown);
                text-decoration: underline;
                margin-bottom: 1rem;
                font-size: 14px;
                border: none;
                
            }

            @include tablet {
                overflow-x: scroll;
                padding-top: 3rem;
            }

            .table-data {
                tbody {
                    tr {
                        td {
                            width: 12% !important;

                            &:nth-child(1) {
                                width: 3% !important;

                                input {
                                    height: 1rem;
                                    width: 25px;
                                }
                            }

                            &:nth-child(3) {
                                padding: 5px;
                            }


                            &:last-child {
                                width: 15% !important;
                            }

                            button {
                                width: 80%;
                                height: 30px;
                                display: inline-block;
                                margin: 4px 4px 4px 4px;
                                font-size: 12px;
                                color: var(--dark);
                                border-radius: 5px;
                                background: none;

                                svg {
                                    margin-left: 1rem;
                                    margin-right: 5px;
                                    color: var(--dark) !important;
                                }

                                a {
                                    color: var(--dark) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.add-product-form {
    width: 100%;

    .alert-dialog {
        width: 350px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-radius: 5px;
        position: relative;

        svg {
            font-size: 1.3rem;
            color: var(--orange);
            position: absolute;
            top: 3%;
            left: 2%;
        }

        .close-x {
            font-size: 1.5rem;
            position: absolute;
            top: 2%;
            right: 2%;
            color: var(--dark);
        }

        p {
            margin: 1rem 0 1rem;
        }

        button {
            background: var(--blue-sombre);
            color: white;
            border-radius: 5px;
            padding: 5px;
            border: none;
            width: 30px;
        }
    }

    .block-form-product {
        background-color: white;
        width: 100%;
        border-radius: 6px;
        overflow: hidden;
        padding-bottom: 2rem;

        .form {
            width: 100%;

            form {
                overflow: hidden;
                margin-top: 2rem;

                .text-mg {
                    color: var(--orange);
                    font-size: 13px;
                    font-style: italic;
                }

                .msg-text {
                    width: 95%;
                }

                h3 {
                    font-size: 14px;
                    color: var(--orange);
                    font-weight: 500;
                    width: 99.5%;
                    background-color: #f169010c;
                    margin-bottom: 12px;
                    padding: 6px 0 6px 10px;

                    &:not(:first-child) {
                        margin-top: 2rem;
                        margin-bottom: 12px;
                    }
                }

                .div-input {
                    width: 95%;
                    height: max-content;
                    display: flex;
                    flex-direction: column;
                    padding: 12px 12px 12px;
                    position: relative;

                    .span-error {
                        position: absolute;
                        left: 19%;
                        top: 88%;
                        font-size: 12px;
                        color: var(--orange);
                    }

                    p {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        position: relative;

                        label {
                            font-size: 13px;
                            width: 170px;
                            text-align: end;

                            span {
                                color: red !important;
                                font-size: 15px;
                                display: none;
                            }
                        }

                        input {
                            margin-left: 1.5rem;
                            width: 500px;
                            height: 36px;
                            border-radius: 2px;
                            background-color: none;
                            padding: 0 10px 0 10px;
                            border: 1px solid var(--whitesmoke-900);
                        }

                        textarea {
                            width: 500px;
                            margin-left: 1.5rem;
                            border: 1px solid var(--whitesmoke-900);
                            border-radius: 2px;
                            padding: 8px;
                        }

                        .btn-click {
                            font-size: 13px;
                            border: none;
                            color: var(--orange);
                            background: none;
                            margin-left: 20%;
                        }

                        .sizes {
                            display: flex;
                            align-items: center;
                            padding-left: 0%;
                            margin-left: 1.4rem;

                            span {
                                font-size: 13px;
                                padding: 2px 12px 2px 12px;
                                border: 1px solid var(--whitesmoke-900);
                                background-color: white;
                                border-radius: 2px;
                                margin-right: 10px;
                                cursor: pointer;

                                &:hover {
                                    background-color: whitesmoke;
                                }
                            }
                        }

                        i {
                            font-size: 12px;
                            margin-left: 1rem;
                        }

                        .color {
                            width: max-content;
                            display: flex;
                            align-items: flex-end;

                            .block-input {
                                width: max-content;
                                display: flex !important;
                                flex-direction: column !important;

                                p {
                                    padding: 0% !important;
                                    width: max-content;
                                    display: flex;
                                    align-items: center;

                                    &:nth-child(even) {
                                        margin: 5px 0 5px;
                                    }

                                    input {
                                        width: 160px;
                                        margin-left: 0% !important;
                                    }

                                    .closes {
                                        font-size: 20px;
                                        color: var(--dark);
                                        padding: 5px;
                                        cursor: pointer;
                                    }

                                }
                            }

                            .add-input {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                float: none !important;
                                color: var(--orange);
                                width: 40px;
                                height: 35px;
                                border: none;
                                margin-left: 1rem;
                                cursor: pointer;
                                background-color: #f169013b;
                            }
                        }

                        @include small {
                            flex-direction: column;
                            align-items: flex-start;
                            width: max-content;

                            label {
                                text-align: start;
                            }

                            input {
                                margin-left: 0%;
                            }

                            textarea {
                                width: 300px;
                                margin-left: 0%;
                            }

                            .file-img {
                                width: 300px;
                                margin-left: 0%;
                                flex-direction: column;
                            }
                        }
                    }

                    .file-img-p {
                        span {
                            font-size: 12px;
                            margin-left: 10px;
                        }
                        .file-img {
                            width: 180px;
                            height: 100px;
                            border-radius: 5px;
                            background-color: rgba(0, 0, 0, 0.1);
                            margin-left: 1.5rem;
                            padding: 10px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            position: relative;

                            span {
                                text-align: center;
                                width: 95%;
                                word-break: break-all;
                                font-size: 12px;
                            }

                            svg {
                                color: var(--whitesmoke-900);
                                font-size: 25px;
                                margin-bottom: 6px;
                            }

                            input {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                opacity: 0;
                            }
                        }

                        img {
                            width: 100px;
                            height: 80px;
                            margin-left: 1rem;
                        }

                        @include small {
                            .file-img {
                                width: 120px;
                                margin-left: 0%;
                            }

                            img {
                                width: 150px;
                                height: 120px;
                            }
                        }
                    }


                    .text-file-img {
                        width: 70%;
                        font-size: 12px !important;
                        color: var(--dark);
                        margin-left: 18%;
                        margin-top: 12px;

                        @include small {
                            margin-left: 0%;
                        }
                    }

                    .div-categorie {
                        height: max-content;
                        width: 673px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        margin-left: 2.7rem;

                        label {
                            font-size: 14px;
                            text-align: end;
                            margin-right: 1.5rem;
                            width: 170px;
                        }

                        .p-item {
                            padding: 5px 0 5px 10px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        .select-categorie {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            background-color: none;
                            border: 1px solid var(--whitesmoke-900);
                            height: 35px;
                            border-radius: 2px;
                            position: relative;
                            font-size: 13px;
                            padding-left: 10px;
                            cursor: pointer;

                            input {
                                width: 100%;
                                border: none;
                                background: none;
                            }

                            .svg-p {
                                background-color: var(--dark);
                                height: 100%;
                                width: 40px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                svg {
                                    font-size: 18px;
                                    color: white;
                                }
                            }

                            .parents-categorie {
                                display: none;
                                flex-direction: column;
                                position: absolute;
                                top: 100%;
                                width: 100%;
                                height: 300px;
                                overflow-y: scroll;
                                z-index: 1;
                                margin-left: 0% !important;
                                border-radius: 6px;
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.355);
                                background-color: white;
                                padding-left: 0% !important;

                                .ul-parent {
                                    width: 98%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    font-size: 13px;

                                    position: relative;

                                    svg {
                                        font-size: 16px;
                                        margin-right: 10px;
                                    }

                                    .ul-child {
                                        display: none;
                                        flex-direction: column;
                                        position: absolute;
                                        top: 100%;
                                        width: 70%;
                                        left: 20%;
                                        z-index: 2;
                                        margin-left: 0% !important;
                                        background-color: white;
                                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.355);
                                        border-radius: 6px;

                                        .subCat {
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            position: relative;
                                            cursor: pointer;

                                            .ul-child3 {
                                                display: none;
                                                background-color: white;
                                                position: absolute;
                                                top: 0%;
                                                width: 70%;
                                                left: 50%;
                                                z-index: 3;
                                                flex-direction: column;
                                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.355);
                                                border-radius: 6px;
                                                margin-left: 5px;
                                                overflow: hidden;

                                                .item-sub {
                                                    cursor: pointer;
                                                    width: 100%;

                                                    &:hover {
                                                        background-color: whitesmoke;
                                                    }
                                                }
                                            }

                                            &:hover {
                                                background-color: var(--whitesmoke-500);

                                                .ul-child3 {
                                                    display: flex;
                                                }
                                            }
                                        }
                                    }

                                    &:hover {
                                        background-color: var(--whitesmoke-500);

                                        .ul-child {
                                            display: flex;
                                        }
                                    }
                                }

                                @include small {
                                    .ul-parent {
                                        .ul-child {
                                            width: 100%;
                                            left: 10%;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .parents-categorie {
                                    display: flex;
                                }
                            }
                        }

                        @include small {
                            flex-direction: column;
                            align-items: flex-start;
                            margin-left: 0%;

                            label {
                                text-align: start;
                            }
                        }
                    }
                }

                .select-country {
                    width: 520px;
                    margin-left: 1.5rem !important;
                }

                .div-file {
                    .span-error {
                        position: static !important;
                        text-indent: 20%;
                    }
                }

                .colos-input {
                    height: 100px;
                    position: relative;
                    margin-bottom: 4rem;

                    textarea {
                        height: 100px;
                    }

                    .notice {
                        font-size: 13px;
                        margin-top: 5px;
                        margin-left: 20%;
                    }
                }

                .moyen-pay {
                    width: 100%;
                    margin-bottom: 10px;

                    .row-pay {
                        margin-left: 20%;
                        width: max-content;

                        .row-child {
                            margin-bottom: 1rem;
                            display: flex;
                            align-items: center;

                        }
                    }
                }

                .text-info {
                    width: 95%;
                    font-size: 13px;
                    margin: 2rem auto !important;
                }

                .btn-update {
                    height: 33px;
                    border-radius: 5px;
                    padding: 0 1.2rem 0 1.2rem;
                    margin-top: 2rem;
                    border: none;
                    background: var(--colorrs);
                    color: white;
                    margin-left: 1rem;
                    margin-bottom: 1rem;
                    font-size: 15px;
                    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.253);
                }
            }

            @include tablet {
                width: 800px;
            }
        }

        @include tablet {
            overflow-x: scroll;
        }
    }

    @include tablet {
        height: 100%;
    }
}

.cobeille-product {
    width: 100%;

    .block-parent {
        .list-product {
            .table-data {
                tbody {
                    tr {
                        td {
                            width: 25%;

                            &:nth-child(1) {
                                width: 15% !important;
                            }

                            &:nth-child(4) {
                                width: 30% !important;
                            }
                        }
                    }
                }
            }
        }
    }
}