@import "../../../globals/mixins.scss";

.product-page {
    width: 100%;

    .section-one-product {
        background: url(../../../assets/images/imgs.png);
        width: 100% !important;
        background-size: cover;
        height: 60vh;
        margin-top: 0% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0% !important;
        position: relative;


        .div-transparent {
            width: max-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 3rem;
      
            h1 {
                font-size: 4rem;
                color: var(--dark);
                width: max-content;
                font-weight: 400;
                margin-bottom: 12px;
                text-align: center;

                input {
                    font-size: 3rem;
                    background: none;
                    width: max-content !important;
                    border: none;
                    text-align: center !important;
                }
            }

            ul {
                text-align: center;
                a {
                    font-size: 14px;
                    color: var(--dark);
                    margin: 0 1rem;
                    display: inline-block;
                }
            }

            @include phone {
                width: 95%;

                h1 {
                    width: 100%;
                }

                input {
                    width: 100% !important;
                    overflow: hidden;
                    word-break: break-all;
                    font-size: 2rem !important;
                }
            }
        }
    }

    .section-two-shop {
        margin-top: 3rem !important;
        display: flex;
        flex-direction: column;
        align-items: center;

        .content-product {
            margin-top: 3rem;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1rem;

            @include tablet {
                grid-template-columns: repeat(2,1fr);
            }

        }

        .content-product2 {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 1.2rem;
            margin-top: 3rem;
        }

        @include phone {
            width: 92% !important;
        }
    }
}