.layout-home2 {
    .header-home {
        border-bottom: 1px solid var(--whitesmoke-500);
        .div-nav {
            .color-link {
                color: var(--dark) !important;
            }
        }
    }
}

.auth-content {
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
}