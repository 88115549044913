@import "../../globals/mixins.scss";

.select {
    width: max-content;
    height: 29px;
    padding: 0 5px 0 5px;
    position: relative;
    margin-right: 15px;
    border-radius: 3px;
    border: 1px solid var(--whitesmoke-500);
    z-index: 1;

    span {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        cursor: pointer;
         svg {
            margin-left: 2rem;
            font-size: 18px;
         }

         span {
            width: max-content;
            height: max-content;
         }
    }

    .options {
        width: 220px;
        padding: 5px 0 5px;
        border-radius: 3px;
        position: absolute;
        top: 120%;
        left: 0%;
        box-shadow: 0 0 5px var(--dark);
        background-color: white;
        
        .close {
            height: 25px;
            width: 25px;
            position: absolute;
            bottom: -2%;
            right: -3%;
            font-size: 25px;
            color: white;
            background: var(--dark) !important;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        li {
            width: 90%;
            font-size: 14px;
            padding: 5px 0 5px 7px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            &:hover {
                color: var(--orange);
            }

            svg {
                font-size: 16px;
            }
        }
    }

    @include small {
        margin-bottom: 10px;
        border: none;
        border-bottom: 1px solid var(--whitesmoke-500);
    }
}