@import "../../globals/mixins.scss";

.card-products {
    width: 100%;
    transition: 0.5s;

    .div-prod {
        width: 100%;
        height: auto;
        position: relative;
        padding-bottom: 0%;

        img {
            width: 100%;
            height: 100%;
            margin-bottom: 0% !important;
        }

        .btn-heart {
            background: rgba(255, 255, 255, 0.486);
            font-size: 1.5rem;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            top: 2%;
            right: 2%;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--dark);
        }

        .btn-view {
            border: none;
            position: absolute;
            bottom: 0%;
            left: 0%;
            width: 100%;
            font-size: 16px;
            color: var(--dark);
            height: 0px;
            overflow: hidden;
            transition: 0.3s;
            cursor: pointer;
        }

        &:hover {
            .btn-view {
                background: rgba(255, 255, 255, 0.692);
                height: 50px;
            }
        }

        @include phone {
            width: 100%;
            height: max-content;
        }
    }

    .info-prod-card {
        width: 100%;
        padding: 1rem 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        >h4 {
            font-size: 13px;
            font-weight: 500;
            color: var(--dark);
            margin-bottom: 2px;
            width: 95%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

       >h5 {
            width: 95%;
            font-size: 15px;
            font-weight: 600;
        }

        .btn-add-cart {
            width: 90%;
            background: var(--dark);
            color: white;
            font-size: 15px;
            height: 35px;
            border: none;
            margin-top: 2rem;
            cursor: pointer;

            &:hover {
                background-color: black;
            }
        }
    }
}

.popup-layout {
    z-index: 5000;
}

.detail-show-popup {
    padding: 2rem 3rem;
    width: 70%;
    height: 90vh;
    background-color: white;
    position: relative;
    overflow-y: scroll;

    >.close {
        float: right;
        font-size: 3rem;
        cursor: pointer;
        position: absolute;
        top: 1%;
        right: 1%;
    }
}