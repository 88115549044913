.popup-card {
    width: 400px;
    padding: 1rem;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    p {
        font-size: 13px !important;
        text-align: center;
    }
    
    button {
        width: 50px;
        background-color: var(--dark);
        color: white;
        font-size: 14px;
        margin-top: 1rem;
        border: none;
        height: 26px;
    }

    svg {
        color: var(--dark);
        font-size: 2rem;
        margin: 12px 0 1rem;
    }

    .close {
        position: absolute;
        top: -2%;
        right: 2%;
        font-size: 2rem;
        color: var(--dark);
    }
}