@import "../../globals/mixins.scss";

.prod-cards {
    width: 100%;
    border: 1px solid var(--whitesmoke-500);
    background-color: var(--whitesmoke-500);
    position: relative;
    overflow: hidden;
    padding-top: 0% !important;

    .bande {
        width: 50px;
        height: 50px;
        font-size: 14px;
        border-radius: 50%;
        background: var(--orange-gradient);
        position: absolute;
        right: 5%;
        bottom: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 49% 71%, 0 100%);
    }

    .imgs {
        width: 100% !important;
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: whitesmoke;
        overflow: hidden;
        margin: 0% !important;

        .btn-link-add {
            border: 1px solid var(--whitesmoke-500);
            background-color: var(--orange);
            font-size: 13px;
            width: max-content;
            height: 26px;
            padding: 0 12px 0 12px;
            border-radius: 30px;
            position: absolute;
            right: 3%;
            bottom: 22%;
            color: white !important;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;

            &:hover {
                background: var(--orange-gradient) !important;
                color: white;
            }
        }

        img {
            height: 100%;
            width: 100%;
            transition: 0.7s;
            margin: 0% !important;

            &:hover {
                transform: scale(1.2);
            }
        }

        &:hover {
            .btn-link-add {
                display: flex;
            }
        }

    }

    .inf {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 10px;

        h4 {
            width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 14px;
            font-weight: 500;
            margin-top: 1rem;
            color: var(--dark);
        }

        p {
            display: flex;
            flex-direction: column;

            b {
                color: var(--dark) !important;
                font-size: 14px;
                font-weight: 600;
                margin: 5px 0 5px;
                white-space: nowrap;
            }

            span {
                font-size: 12px;
                color: var(--orange);
                text-decoration: line-through;
            }
        }
    }

    @include desktop_md {
        .imgs {
            .btn-link-add {
                display: flex;
                align-items: center;
                background-color: var(--orange);
                color: white;
            }
        }
    }

    @include tablet {
        .imgs {
            height: 200px;
        }
    }

    @include small {
        width: 100% !important;

        .imgs {
            height: max-content !important;
            .btn-link-add {
                font-size: 10px;
                bottom: 35%;
                display: flex !important;
                align-items: center;
                background: var(--orange);
                color: white;
            }

            img {
                height: max-content !important;
            }
        }

        .inf {
            h4 {
                margin-bottom: 0% !important;
            }

            p {
                flex-direction: column !important;

                b {
                    margin-bottom: 0%;
                    font-size: 13px;
                }
            }
        }
    }
}