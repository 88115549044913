@import "../../../globals/mixins.scss";

.product-detail-page {
    width: 100%;
    margin-bottom: 5rem;
    padding-top: 5rem;

    .section-details-produit {
        width: 80%;
        margin: auto;
        padding-top: 4rem;

        .head-details-prod {
            margin-bottom: 2rem;
            width: 100%;

            p {
                color: var(--dark);
                font-size: 14px;
            }
        }

        .produit-desc-avis {
            margin-top: 2rem;
            width: 100%;

            .div-bloc {
                width: 100%;

                h4 {
                    display: flex;
                    align-items: center;
                    font-size: 24px;
                    font-weight: 500;
                    color: var(--dark);
                    margin-bottom: 1rem;

                    hr {
                        width: 100px;
                        margin-left: 1rem;
                        background-color: var(--whitesmoke-500);
                    }
                }

                .div1 {
                    width: 100%;
                    margin-bottom: 3rem;

                    p {
                        width: 70%;
                        margin: auto;
                        font-size: 14px;
                        color: var(--dark);
                    }

                    @include tablet {
                        p {
                            width: 100%;
                        }
                    }
                }

                .div-avis {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    h4 {
                        width: 100%;
                    }

                    .btn-first {
                        width: 200px;
                        font-size: 16px;
                        color: var(--dark);
                        background: none;
                        height: 50px;
                        margin: 3rem 0 1rem;
                        border: 2px solid var(--dark);

                        &:hover {
                            background: var(--whitesmoke-500);
                        }
                    }

                    form {
                        margin-top: 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 600px;

                        #msg-form {
                            color: var(--orange) !important;
                            height: 40px;
                            width: 100%;
                            display: none;
                            align-items: center;
                            justify-content: center;
                            background-color: rgba(255, 0, 0, 0.122);
                        }

                        p {
                            font-size: 14px;
                            color: var(--dark);
                            text-align: center;
                        }

                        .note-product {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 1rem 0 1rem;
                            background: rgb(250, 250, 250);
                            padding: 10px 0 10px;

                            p {
                                display: flex;
                                align-items: center;
                                margin-left: 1rem;

                                span {
                                    cursor: pointer;
                                    margin: 0 8px;
                                    font-size: 1.3rem;

                                    svg {
                                        color: rgba(232, 232, 232, 0);
                                        fill: rgb(215, 215, 215) ;
                                    }
                                }

                                .s1 {
                                    &:hover {
                                        svg {
                                            fill: var(--dark);
                                        }
                                    }
                                }
                                .s2:hover > svg{
                                    fill: var(--dark);
                                }

                                .s3:hover > svg, .s4:hover > svg{
                                    fill: var(--dark);
                                }

                                .s4:hover > svg{
                                    fill: var(--dark);
                                }

                                .s5:hover > svg{
                                    fill: var(--dark);
                                }
                            }
                        }

                        .div-field {
                            width: 100%;
                            margin: 1.8rem 0 1rem;

                            textarea {
                                width: 95%;
                                padding: 10px;
                                height: 100px;
                                border: 2px solid var(--whitesmoke-500);
                            }
                        }

                        .div-fields {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;

                            p {
                                display: flex;
                                flex-direction: column;
                                width: 48%;

                                input {
                                    font-size: 14px;
                                    width: 93.5%;
                                    padding: 0 10px 0 10px;
                                    border: 3px solid var(--whitesmoke-500);
                                    height: 40px;
                                }
                            }
                        }

                        .infos {
                            margin: 1.5rem 0 2rem;
                            display: flex;
                            align-items: center;

                            input {
                                height: 45px;
                                width: 20px;
                                margin-right: 1rem;
                            }
                        }

                        .btn-send {
                            width: 200px;
                            border: none;
                            font-size: 16px;
                            background: var(--dark);
                            height: 40px;
                            color: white;

                            &:hover {
                                background: black;
                            }
                        }

                        @include tablet {
                            width: 100%;
                        }
                    }

                    .reviews-block {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: start;

                        .reviews-div {
                            display: flex;
                            width: 500px;
                            flex-direction: column;
                            border: 1px solid var(--whitesmoke-500);
                            padding: 5px;

                            .head-r {
                                width: 100%;
                                display: flex;
                                align-items: center;

                                svg {
                                    font-size: 2.5rem;
                                    fill: var(--whitesmoke-500);
                                    margin-right: 12px;
                                }

                                p {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: start;

                                    h3 {
                                        font-size: 14px;
                                        font-weight: 500;
                                        margin-bottom: 5px;
                                    }

                                    span {
                                        font-size: 12px;
                                    }
                                }
                            }

                            .comment {
                                font-size: 13px !important;
                                text-align: justify;
                                margin-top: 1rem;
                                color: var(--dark);
                                padding-left: 3.6rem;
                            }
                        }
                    }
                }
            }
        }

        .product-similar {
            width: 100%;
            margin-top: 4rem;

            .title-ps {
                display: flex;
                align-items: center;
                font-size: 24px;
                font-weight: 500;
                color: var(--dark);
                margin-bottom: 1rem;

                hr {
                    width: 100px;
                    margin-left: 1rem;
                    background-color: var(--whitesmoke-500);
                }
            }

            .slide-div-prod {
              width: 100%;

              .card-products {
                width: 220px;

                .btn-view  {
                    display: none !important;
                }
              }
            }
        }

        @include tablet {
            width: 90%;
        }
    }
}