@import "../../globals/mixins.scss";

.popup-content {
    overflow-y: scroll !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .load {
    width: 400px;
    padding: 2rem;
    background-color: white;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 13px;
      text-align: center;
    }

    button {
      font-size: 13px;
      color: white;
      margin-top: 1rem;
      width: 35px;
      height: 25px;
      background: var(--dark);
      border: none;
    }
  }
  
  .form-create-store {
    margin-top: 0rem !important;
    width: 65%;
    height: 90vh;
    background-color: white;
    border-radius: 5px;
    overflow-y: scroll;
  
    .closes {
      font-size: 3rem;
      color: var(--link-color);
      position: absolute;
      top: 0%;
      right: 2%;
      cursor: pointer;
    }
  
    h2 {
      font-size: 1.2rem;
      font-weight: 400;
      margin-bottom: 8px;
      text-align: center;
      text-decoration: underline;
    }
  
    p {
      margin: 0%;
    }
  
    h3 {
      font-size: 20px;
      font-weight: 500;
      text-decoration: underline;
      margin-bottom: 2rem;
    }
  
    form {
      width: 92%;
      height: 130vh;
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2rem auto;

      h4 {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        color: var(--red) !important;
      }
  
      .step {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .msg-text {
          color: var(--orange);
          background-color: rgba(240, 136, 8, 0.097);
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
  
        button {
          padding: 0 1.5rem 0 1.5rem;
          height: 35px;
          font-size: 15px;
          background: var(--dark) !important;
          border: none;
          color: white;
          margin-top: 1.5rem;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
  
          svg {
            font-size: 18px;
            margin-left: 5px;
          }
        }
      }
  
      p {
        font-size: 14px;
        margin-bottom: 10px;
        position: relative;

        .eye {
          top: 75%;
        }
  
        a {
          color: var(--red-color);
          font-weight: 600;
        }
  
        input {
          height: 1rem;
          width: 20px;
          margin-right: 5px;
        }
      }
  
      label {
        margin-bottom: 8px;
        font-size: 13px !important;
        color: var(--link-color);
  
        span {
          color: var(--red-color);
        }
      }
  
      .div-input {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
  
        p {
          width: 48%;
          display: flex;
          flex-direction: column;
          position: relative;
  
          input,
          select {
            width: 100%;
            height: 32px;
            color: var(--dark);
            font-size: 14px;
            text-indent: 6px;
            border: 1px solid var(--dark);
          }

          input {
            width: 98%;
          }

          select {
            height: 38px;
          }
  
          .select-country {
            height: 35px;
            border-radius: 0;
            border-color: var(--link-color);
          }

          textarea {
            width: 100%;
            padding: 5px;
          }

          .err {
            font-size: 12px;
            color: var(--orange);
            position: absolute;
            top: 100%;
          }
        }
      }
  
      .msg-succes-div {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3rem 0 3rem;
  
        svg {
          font-size: 3rem;
          color: green;
          margin-bottom: 1rem;
        }
  
        p {
          font-size: 14px !important;
          margin-top: 1rem;
        }
      }

      @include phone {
        .div-input {
          flex-direction: column;

          p {
            width: 100%;
          }

          textarea {
            width: 97% !important;
          }
        }
      }

      @include small {
        width: 100%;
      }
    }

    @include phone {
      width: 80%;
    }
  }