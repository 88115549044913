@import "../../../../globals/mixins.scss";

.categorie-page {
    width: 100%;

    .categorie {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;

        .child {
            width: 63%;
        }

        .add-categorie {
            width: 32%;
            margin-right: 10px;
            margin-top: 12px;

            form {
                width: 92%;
                background-color: whitesmoke;
                border: 1px solid var(--whitesmoke-500);
                padding: 1rem;

                .msg-text {
                    font-size: 12px !important;
                    color: rgb(0, 196, 0);
                    background-color: rgb(255, 255, 255);
                    width: 93%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 8px;
                    margin-bottom: 10px;
                }

                h3 {
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 1rem;
                    text-decoration: underline;
                }

                div {
                    width: 98%;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 12px;

                    label {
                        font-size: 13px;
                        color: var(--dark);
                        margin-bottom: 8px;
                    }

                    input {
                        text-indent: 5px;
                        height: 35px;
                        width: 100%;
                        background-color: white;
                        border: 1px solid var(--whitesmoke-500);
                    }

                    textarea {
                        width: 98%;
                        padding: 5px;
                        background-color: white;
                        border: 1px solid var(--whitesmoke-500);
                    }

                    span {
                        font-size: 13px;
                        color: var(--red);
                        margin-top: 5px;
                    }
                }

                button {
                    height: 31px;
                    width: max-content;
                    padding: 0 1rem 0 1rem;
                    background: var(--dark);
                    color: white;
                    font-size: 14px;
                    margin-top: 12px;
                    border: none;
                }
            }
        }

        @include tablet {
            flex-direction: column !important;

            .child {
                width: 100%;
            }

            .add-categorie {
                margin-top: 2rem;
                width: 500px;
            }
        }
    }
}