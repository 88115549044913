@import "../../../../globals/mixins.scss";

.favoris-dashboard-buyer {
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 0% !important;
    padding-top: 0% !important;
    margin-bottom: 5rem;

    .favoris-blocks {
        border: none !important;
    }

    .favoris-tabs {
        width: 100%;
        height: 100%;

        .btn-tabs-switch {
            margin-bottom: 12px;

            button {
                font-size: 14px;
                padding: 6px 1rem 6px 1rem;
                border: none;
                background: none;
            }
        }

        .favoris-product {
            margin-top: 2rem;
            width: 100%;
            height: 80vh;
            overflow-y: scroll;
            overflow-x: none !important;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1rem;
            row-gap: 0%;

            .card-favoris {
                width: 100%;
                height: max-content;
                display: flex;
                flex-direction: column;
                margin-bottom: 0% !important;
                border: 1px solid var(--whitesmoke-500);
                background-color: var(--whitesmoke-500);

                .img {
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 6px;
                    background-color: rgb(250, 250, 250);

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .infos-div {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    padding: 5px 0 8px;

                    h3 {
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 4px;
                        color: var(--dark);
                    }

                    p {
                        font-size: 1rem;
                        color: var(--dark);
                    }
                }
            }

            @include desktop_md {
                grid-template-columns: repeat(4, 1fr);
            }

            @include phone {
                grid-template-columns: repeat(3, 1fr);
            }

            @include small {
                grid-template-columns: repeat(2, 1fr);

                .card-favoris {
                    .img {
                        height: 120px;
                    }
                }
            }
        }
    }
}