@import "../../../../globals/mixins.scss";

.home-dashboard-buyer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .block-dashboard {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        @include phone {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .block-cards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 20px;

        .cards {
            padding: 12px 0;
            width: 100%;
            height: 120px;
            background-color: white;
            border-radius: 2px;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            p {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                width: 90%;

                input  {
                    border: none;
                    background: none;
                    font-size: 2rem;
                    margin-bottom: 1px !important;
                    font-weight: 500;
                }
                span {
                    font-size: 14px;
                }
            }

            .barss {
                height: 5px;
                width: 90%;
                background-color: rgb(255, 164, 73);
            }

            &:nth-child(2) {
                .barss {
                    background-color: rgb(91, 223, 91);
                }
            }

            &:nth-child(3) {
                .barss {
                    background-color: var(--colorrs);
                }
            }

            &:last-child {
                background: rgb(66, 67, 66);

                .info-account {
                    display: flex;
                    align-items: center;
                    padding: 0 10px;
                    p {
                        span {
                            color: white;
                            font-size: 13px;
                        }
                    }

                    .div-percent {
                        width: 90%;
                        display: flex;
                        flex-direction: column;
                        margin: auto;
                        
                        p {
                            font-size: 13px;
                            color: white;
                        }
                        .bars {
                            width: 100%;
                            height: 10px;
                            border-radius: 10px;
                            margin-top: 10px;
                            position: relative;
                            overflow: hidden;
                            background-color: rgba(255, 255, 255, 0.168);

                            &::before{
                                content: "";
                                position: absolute;
                                top: 0%;
                                left: 0%;
                                width: 40%;
                                height: 100%;
                                background-color: white;
                            }
                        }
                    }
                }
                img {
                    margin-left: 1rem;
                    height: 60px;
                    width: auto;
                    border-radius: 50%;
                }
            }

            @include phone {
                height: 100px;
            }
        }

        @include tablet {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;

            .cards {
                width: 95% !important;
            }
        }

        @include phone {
            grid-template-columns: repeat(1,1fr);
        }
    }

    .card-graph {
        height: 300px;
        width: 100%;
        background-color: none;
        margin-top: 2rem;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .line-chart {
            width: 450px;
        }

        .div1s {
            width: 47.2%;
            height: 100%;
            background-color: white;
            padding: 8px;
            box-shadow:  0 0 15px rgba(0, 0, 0, 0.1);
        }

        .link-visits {
            width: 100% !important;
            height: 95%;
            background-color: white;
            box-shadow:  0 0 15px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction:  column;
            align-items: center;
            padding: 1rem;

            h3 {
                font-size: 16px;
                font-weight: 200;
                border-bottom: 1px solid var(--dark);
                width: 100%;
                padding-bottom: 5px;
                display: flex;
                align-items: center;

                svg {
                    font-size: 15px;
                    margin-left: 1rem;
                }
            }

            .div-action-rapide {
                width: 100%;
                margin-top: 1rem;
                display: flex;
                flex-direction: column;

                a {
                    font-size: 13px;
                    color: var(--deep-brown);
                    margin-bottom: 12px;

                    svg {
                        font-size: 14px;
                        margin-right: 10px;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .link-divs {
                width: 100%;
                height: 40%;
                background-color: var(--colorrs);
                display: flex;
                align-items: center;
                justify-content: center;

                .visit-store {
                    font-size: 15px;
                    color: white;
                    display: flex;
                    align-items: center;
                    text-decoration: underline;
        
                    svg {
                        font-size: 1.8rem;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

}