@import "../../globals/mixins.scss";

.ProduitDetails {
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    position: relative;

    >.close {
        position: absolute;
        right: 2%;
        top: 2%;
        font-size: 2rem;
        display: none;
    }

    .popup-card {
        width: 40%;
        padding: 1.5rem;
        border-radius: 2px;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .close-x {
            font-size: 2rem;
            color: var(--dark);
            position: absolute;
            top: 2%;
            right: 2%;
        }

        p {
            text-align: center;
            font-size: 13px !important;
        }

        .btns-action {
            margin-top: 2rem;
            display: flex;
            align-items: center;

            button {
                width: max-content;
                height: 28px;
                border: none;
                font-size: 13px;
                padding: 0 1rem 0 1rem;
                margin: 0 12px;
                border-radius: 2px;

                &:nth-child(1) {
                    background: var(--dark);
                    color: white;

                    &:hover {
                        background: var(--whitesmoke-900);
                    }
                }

                &:nth-child(2) {
                    background: none;
                    color: var(--dark);
                    border: 1px solid var(--dark);

                    &:hover {
                        background: rgba(0, 0, 0, 0.116);
                    }
                }
            }
        }

        @include small {
            width: 75%;
        }
    }

    .div-img {
        width: 45%;
        height: 450px;
        display: flex;
        flex-direction: column;
    }

    .image-prod {
        width: 100%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(240, 240, 240);

        >img {
            width: 100%;
            height: 90%;
            object-fit: contain;
        }
    }

    .name-shop {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        >svg {
            font-size: 1.1rem;
            margin-right: 6px;
        }
    }

    .div-details {
        width: 50%;

        h1 {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 1rem;
            color: var(--dark);
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-bottom: 1px solid var(--whitesmoke-900);
            padding-bottom: 3px;
        }

        b {
            font-size: 30px;
            font-weight: 500;
            color: var(--dark);

            sup {
                font-size: 15px;
            }
        }

        .desc-prod {
            display: flex;
            margin-top: 2rem;

            span {
                font-size: 14px;
                color: var(--dark);

                &:nth-child(1) {
                    font-weight: 600;
                    margin-right: 12px;
                    white-space: nowrap;
                }
            }
        }

        .quantity {
            width: 100%;
            margin-top: 0rem;
            display: flex;
            align-items: center;
            padding-top: 1rem;

            @include small {
                flex-direction: column;
                align-items: start;
            }

            .qty {
                display: flex;
                align-items: center;
                border: 1px solid var(--dark);
                padding: 10px;

                @include small {
                    width: 100%;
                    padding: 8px 0;
                    justify-content: space-around;
                }

                button {
                    font-size: 18px;
                    color: var(--dark);
                    background: none;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include small {
                        background-color: rgb(240, 240, 240);
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                    }
                }

                input {
                    width: 40px;
                    color: var(--dark);
                    text-align: center;
                    border: none;
                    font-size: 18px;
                    margin: 0 5px;
                }
            }

            h4 {
                font-size: 18px;
                color: var(--dark);
            }
        }

        label {
            text-transform: capitalize;
            color: var(--dark);
            margin-right: 1.2rem;
            white-space: nowrap;
            font-size: 14px !important;
        }

        .select-div {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            width: 100%;

            @include small {
                flex-direction: column;
                align-items: start;
                margin-bottom: 8px;
            }

            label {
                width: 13%;
            }

            .select {
                color: var(--dark);
                width: 60%;
                height: 40px;
                border: 1px solid var(--whitesmoke-900);
                font-size: 14px;
                border-radius: 0px !important;

                @include small {
                    width: 100%;
                }
            }

            span {
                color: var(--orange);
                font-size: 12px !important;
                width: 26%;

                @include small {
                    width: 100%;
                }
            }
        }

        .btn-add-cart {
            width: 100%;
            height: 45px;
            margin-top: 1.5rem;
            background: var(--dark);
            color: white;
            border: none;
            font-size: 16px;
            margin-bottom: 1rem;

            &:hover {
                background: black;
            }
        }

        .brand-category {
            width: 100%;
            padding: 1rem 0 1rem;
            margin-bottom: 1rem;
            border-top: 1px solid var(--whitesmoke-500);
            border-bottom: 1px solid var(--whitesmoke-500);

            p {
                color: var(--dark);
                font-size: 14px;

                b {
                    margin: 0 1rem 0 12px;
                    font-size: 13px !important;
                }
            }
        }

        .share {
            display: flex;
            align-items: center;

            span {
                font-size: 13px;
                margin-right: 1rem;
            }

            div {
                display: flex;
                align-items: center;

                li {
                    margin: 0 5px;
                }
            }
        }
    }

    @include tablet {
        flex-direction: column;

        .div-img {
            width: 100%;
            height: auto;
            margin-bottom: 2rem;
        }

        .div-details {
            width: 100%;
        }
    }
}