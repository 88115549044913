@import "../globals/mixins.scss";

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

*,
::after,
::before {
    margin: 0%;
    padding: 0%;
}

body {
    width: 100% !important;
    font-family: 'Poppins';
    overflow-x: hidden;
    padding: 0% !important;
}

section {
    width: 85%;
    margin: 5rem auto;
}
.select-adr {
    background: var(--orange-transparent) !important;
    border: 2px solid var(--orange);

    .check {
        display: block !important;
    }
}

.svgFill {
    svg {
        fill: var(--dark) !important;
    }
}

.active-size-filter {
    border: 2px solid var(--orange);
    background: rgba(255, 136, 0, 0.303);
}

.active-color-filter {
    transform: scale(1.5);
}

.active-cat {
    font-size: 2rem !important;
    font-weight: 600;
}

.link-color-dark {
    color: var(--dark) !important;
}

.bg-detail-prod {
    width: 80%;
    background-color: white;
    padding: 2rem;
    position: relative;

    .close {
        font-size: 3rem;
        color: var(--dark);
        cursor: pointer;
        position: absolute;
        right: 1%;
        top: 0%;
    }
}

.title-section {
    font-weight: 500;
    font-size: 35px;
    margin-bottom: 1rem;
    width: 50%;
    color: var(--dark);
    text-align: center;
}

li {
    list-style: none;
}

button {
    cursor: pointer;
    transition: 0.4s;
    outline: none;
}

input {
    outline: none;
}

textarea,
select {
    outline: none;
}

.clos {
    height: 0vh !important;
}

.ope {
    padding: 1rem 0 1rem;
    height: max-content !important;
}

.common-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.parent-body {
    width: 100% !important;
}

.body-page {
    width: 100%;
    margin: auto;
}

.form-add {
    padding: 1rem;
    width: 500px;
    border-radius: 10px;
    background-color: var(--whitesmoke-500);
    position: relative;

    .close-times {
        position: absolute;
        top: -50%;
        right: 3%;
        font-size: 30px;
        color: var(--dark);
        cursor: pointer;
    }

    h3 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .div-inputs {
        p {
            width: 34%;

            &:nth-child(3) {
                width: 25%;
            }

            .select-country {
                height: 33px !important;
                border-radius: 6px;

                .selected {
                    span {
                        font-size: 4rem !important;
                        color: var(--dark);

                        &:nth-child(2) {
                            font-size: 13px !important;
                        }
                    }
                }

                .options-select {
                    .option {
                        span {
                            &:nth-child(1) {
                                font-size: 4rem !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.color-dark {
    color: var(--dark) !important;
}

.bg-white {
    background: white !important;
}

.header-static {
    background: white !important;
    position: static !important;
    border-bottom: 1px solid var(--whitesmoke-500);
}

a {
    text-decoration: none !important;
}

span {
    cursor: pointer;
}

button {
    transition: 0.4s;
}

.table-data {
    width: 100%;
    height: max-content;
    border-collapse: collapse;
    thead {
        width: 100%;
        background-color: rgb(243, 243, 243);
        tr {
            border: 1px solid rgb(199, 199, 199) !important;
            height: 35px;
            th {
                height: 100%;
                font-size: 12px;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }

    tbody {
        width: 100%;
        tr {
            &:nth-child(even) {
                border-bottom: 1px solid var(--whitesmoke-900);
            }
            td {
                height: 40px;
                font-size: 12px;
                padding: 6px;
                text-align: center;
                border-bottom: 1px solid var(--whitesmoke-500) !important;

                &:nth-child(3) {
                    width: 35%;
                }

                &:last-child {
                    text-align: center;

                    button {
                        background: none;
                        border: none;
                        margin: 0 12px;
                        font-size: 12px;
                        svg {
                            font-size: 15px;
                            color: var(--orange);
                        }

                        &:last-child {
                           svg {
                             color: red;
                           }
                        }
                    }
                }
            }
        }
    }

    @include tablet {
        width: 800px !important;
    }
}

.parent {
    width: 98.2% !important;
    height: max-content;
    border: 1px solid var(--whitesmoke-500);
    background-color: white;
    padding: 10px;
    padding-bottom: 2rem !important;

    @include tablet {
        overflow-x: scroll;
    }
}

.active-whishlist-btn {
    fill: rgb(255, 157, 0) !important;
    color: rgb(255, 157, 0);
}