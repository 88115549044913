@import "../../globals/mixins.scss";

.background-form {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0%;
    top: 0%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;

    .block-form {
        width: 550px;
        background: white !important;
        border-radius: 3px;
        padding: 1.5rem 0 1rem;
        position: absolute;

        @include small {
            width: 90%;
        }

        .close {
            font-size: 2.5rem;
            color: var(--dark);
            position: absolute;
            right: 3%;
            top: 0%;
            cursor: pointer;
        }
    }


}

.form-login-register {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-reset-pwd {
        margin-top: 1rem;
    }

    .reset-pwd-email {
        margin-top: 1rem;
    }

    .msg-text {
        font-size: 13px !important;
        color: var(--orange);
    }

    .text-error {
        width: 100%;
        padding: 10px 0 10px;
        color: var(--orange);
        text-align: center;
        background: var(--orange-transparent);
    }

    .notice-pwd {
        font-size: 12px;
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 2rem;
    }

    .div-field {
        width: 90%;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        position: relative;

        .phones {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 13px;
            }

            input {
                width: 88% !important;

                &:nth-child(1) {
                    width: 40px !important;
                    text-align: center;
                }
            }
        }

        a {
            position: absolute;
            right: 0%;
            top: 0%;
            font-size: 12px;
            color: var(--blue);
            cursor: pointer;
        }

        .groups-field {
            display: flex;
            justify-content: space-between;

            p {
                width: 48%;
                display: flex;
                flex-direction: column;
                position: relative;

                input {
                    width: 96%;
                    border: 1px solid var(--whitesmoke-900) !important;
                    background: none !important;
                    border-radius: 2px !important;
                }

                span {
                    top: 100% !important;
                }

                .eye {
                    top: 55% !important;
                }
            }
        }

        label {
            margin-bottom: 5px;
            font-size: 14px;
        }

        input {
            width: 98%;
            height: 38px;
            border: 1px solid var(--whitesmoke-900);
            color: var(--dark);
            border-radius: 2px;
            background: none;
            font-size: 14px;
            padding: 0 5px 0 5px;
            transition: 0.5s;

            &:focus {
                box-shadow: 0 0 3px var(--orange);
                background: none;
            }
        }

        .span {
            font-size: 12px;
            color: var(--orange);
            position: absolute;
            bottom: -50%;
            right: 0%;
        }
    }

    p {
        input {
            border-radius: 5px !important;
            background-color: var(--whitesmoke) !important;
            border: 1px solid var(--whitesmoke-500) !important;
        }
    }

    .text-foot {
        width: 100%;
        font-size: 12px;

        a {
            color: var(--blue)
        }
    }

    .btn-submit {
        margin: 1.5rem 0 10px;

        button {
            background: var(--dark);
            font-size: 14px;
            color: white;
            border: none;
            height: 34px;
            padding: 0 14px;
            border-radius: 2px;
        }
    }

    .foot-form {
        font-size: 13px;

        a {
            font-size: 16px;
            margin-left: 10px;
            color: var(--dark);
            text-decoration: underline !important;
            font-weight: 600;
            cursor: pointer;
        }
    }

    @include small {
        .text-foot {
            font-size: 12px !important;
        }

        .btn-tabs {
            width: 100%;
            display: flex;
            justify-content: center;

            h3 {
                font-size: 14px;
                font-weight: 400;
                margin: 0% 4%;
            }
        }

        label {
            font-size: 12px;
        }

        .div-field {

            input,
            .select-country {
                height: 28px;
                font-size: 12px;
            }
        }
    }
}

.msg-succes-active-account {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        text-align: center;
        font-size: 14px;
        margin-bottom: 1.5rem;
    }

    button {
        background-color: var(--dark);
        font-size: 15px;
        color: white;
        padding: 7px 1rem 7px 1rem;
        border-radius: 2px;
    }
}

.form-resett {
    .div-field {
        margin-top: 1rem;

        .eye {
            top: 60% !important;
        }
    }
}

.succes-div-send {
    border-radius: 5px;
    padding: 1rem;
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        font-size: 13px;
        color: var(--dark);
        text-align: center;
    }

    a {
        border-radius: 3px;
        padding: 5px 2rem 5px 2rem;
        text-decoration: none;
        color: white;
        background: var(--dark);
        font-size: 14px;
        margin: 2rem 0 2rem;
    }
}

.reset-pwd-form {
    background-color: white !important;
}

.login-block {
    width: 500px;
    background-color: white;
    padding: 1.5rem 0 1.5rem;

    h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    #err {
        color: red;
        font-size: 13px !important;
    }
}

.login-admin {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-block {
        background-color: whitesmoke;
        padding: 1.5rem 0 1.5rem;

        h2 {
            text-decoration: underline;
        }
    }
}