@import "../../../globals/mixins.scss";

.dashboard-seller-home {
    width: 100%;
    height: 100vh !important;
    overflow: hidden;

    .open-sub-menu {
        height: max-content !important;
    }

    .main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: var(--whitesmoke);


        .main-center {
            padding-bottom: 2rem;
            height: max-content;
            display: flex;
            justify-content: space-between;

            .nav-bar {
                width: 200px;
                height: 100vh;
                display: flex;
                align-items: center;
                flex-direction: column;
                padding-top: 0px;
                background: var(--deep-brown);
                box-shadow: 0 5px 5px var(--dark);
                position: relative;

                .close-mobile-nav {
                    background: var(--dark);
                    font-size: 2rem;
                    color: white;
                    cursor: pointer;
                    position: absolute;
                    right: 2%;
                    z-index: 1;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    display: none;
                    align-items: center;
                    justify-content: center;
                }

                .logo {
                    width: 100%;
                    display: flex;
                    height: 58px;
                    align-items: center;
                    justify-content: center;
                    background: var(--deep-brown) !important;
                    padding: 5px 0 5px;

                    img {
                        width: 50px;
                    }

                    span {
                        color: white;
                        font-size: 14px;
                        margin-left: 10px;
                    }
                }

                .shop {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    padding: 8px 0 8px ;
                    margin-top: 0px;
                    background: none;
                    border-top: 1px solid var(--whitesmoke-900);
                    border-bottom: 1px solid var(--whitesmoke-900);

                    span {
                        font-size: 13px;
                        font-weight: 500;
                        width: max-content;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: white;
                    }

                    svg {
                        fill: whitesmoke;
                        font-size: 20px;
                        margin-right: 8px;
                        margin-left: 12px;
                    }
                }

                .nav {
                    width: 100%;
                    margin-top: 0px;
                    padding-top: 20px;

                    

                    .bar {
                        height: 1px;
                        width: 100%;
                        background-color: var(--whitesmoke-500);
                        margin: 5px 0 5px;
                    }

                    .nav-ul {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        cursor: pointer;
                        width: 100%;
                        padding: 0%;
                        padding-bottom: 0% !important;
                        overflow: hidden;

                        .link-p {
                            color: white;
                            padding: 10px 0 10px 10px;
                            width: 97%;
                            height: max-content;
                            display: flex;
                            align-items: center;
                            position: relative;
                            justify-content: space-between;

                            span {
                                display: flex;
                                align-items: center;
                                font-size: 13px;

                                svg {
                                    font-size: 17px;
                                    color: var(--whitesmoke-500);
                                }
                            }

                            svg {
                                font-size: 15px;
                                margin-right: 10px;
                            }

                            &:hover {
                                background-color: rgba(0, 0, 0, 0.386);
                                &::before {
                                    display: block;
                                }
                            }
                        }

                        .sub-ul {
                            height: 0;
                            overflow: hidden;
                            width: 100% !important;
                            padding: 0%;
                            padding-left: 0%;
                            background-color: rgb(83, 80, 80);

                            li {
                                padding: 7px 0 7px 12px;
                                font-size: 13px;
                                width: 94%;
                                margin-bottom: 0% !important;
                                color: white;
                            }
                        }
                    }

                    button {
                        width: 100%;
                        font-size: 14px;
                        color: whitesmoke;
                        height: 40px;
                        margin-top: 1rem;
                        background: none;
                        border: none;
                        text-align: start;
                        display: flex;
                        align-items: center;
                        justify-content: start;
                        border-top: 1px solid var(--whitesmoke-900);

                        svg {
                            font-size: 15px;
                            margin: 0 8px 0 6px;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .footer {
                    position: absolute;
                    bottom: 1%;

                    p {
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                @include tablet {
                    transition: 0.1s;
                    width: 0;
                    z-index: 1000;
                    overflow: hidden;

                    .close-mobile-nav {
                        display: flex;
                    }
                }
            }

            .body {
                width: 100%;
                border: 1px solid;
                height: 98%;
                position: relative;
                padding-bottom: 0rem;

                .bar-head-mobile {
                    width: 100%;
                    height: 40px;
                    background: var(--deep-brown);
                    display: none;
                    align-items: center;
                    z-index: 10000;

                    .btn-menu {
                        border: none;
                        background: none;
                        margin-left: 10px;

                        svg {
                            color: whitesmoke;
                            font-size: 1.8rem;
                        }
                    }

                    @include desktop-md {
                        display: flex;
                    }
                }

                .content-body {
                    overflow-y: scroll;
                    width: 100%;  
                    display: flex;
                    flex-direction: column;                      
                    height: 95vh !important;
                    padding-bottom: 5rem;

                    .block-content {
                        padding-bottom: 3rem;
                        width: 96%;
                        margin: 1rem auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        @include tablet {
                            width: 93%;
                        }
                    }
                }

                @include tablet {
                    width: 100%;
                }
            }
        }
    }
}