@import "../../globals/mixins.scss";
.searchbar {
    width: 100%;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 5px 0 5px;
    margin-bottom: 1rem;
   
    @include phone  {
        display: flex;
        flex-direction: column-reverse;
        align-items: end;

        p {
            margin-top: 12px;
        }
    }
}

.btn_print svg {
    margin-right: 5px;
}

.bar {
    width: 380px;
    position: relative;
    display: flex;
    justify-content: end;
    border-radius: 30px;
    overflow: hidden;
    background-color: var(--colorrs) !important;

    @include small {
        width: 100%;

        input {
            width: 84% !important;
        }
    }
}

.bar input {
    width: 84%;
    height: 28px;
    border-radius: 0px;
    background-color: none;
    color: var(--dark);
    padding: 0 10px 0 10px;
    border: 1px solid var(--whitesmoke-500);
    font-size: 13px;
}

.bar svg {
    font-size: 16px;
    color: white;
    position: absolute;
    top: 50%;
    left: 6%;
    transform: translate(-50%, -50%);
}

