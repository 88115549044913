@import "../../../../globals/mixins.scss";

.revenu-fond-page {
    width: 100%;

    .requestForm {
        background-color: white;
        width: 400px;
        padding: 1rem;
        border-radius: 3px;
        position: relative;

        .close {
            cursor: pointer;
            font-size: 1.5rem;
            color: var(--dark);
            position: absolute;
            right: 2%;
            top: 1%;
        }

        form {
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;

            h3 {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 1rem;
            }

            div {
                width: 100%;
                >input {
                    height: 100%;
                    border: 1px solid var(--whitesmoke-900);
                    border-radius: 3px;
                    width: 100%;
                    padding: 8px 0px;
                    text-indent: 5px;
                }

                >span {
                    font-size: 13px;
                    color: rgb(237, 34, 34);
                    margin-top: 2px;
                }

                >button {
                    margin-top: 10px;
                    padding: 8px 13px;
                    background: var(--dark);
                    color: white;
                    border-radius: 3px;
                    font-size: 14px;
                    border: none;
                }
            }
        }
    }

    .block-revenu {
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;

        >.div1 {
            width: max-content;
            display: flex;
            flex-direction: column;

            >div {
                display: flex;
                align-items: center;
                margin-top: 2rem;

                >&:nth-child(1) {
                    >button{
                        font-size: 15px;
                        background: var(--dark);
                        color: white;
                        padding: 8px 12px;
                        border-radius: 2px;
                        margin-left: 1.5rem;
                        border: none;
                    }

                    p{
                        font-size: 14px;

                        input {
                            font-size: 15px;
                            font-weight: 600;
                            padding: 5px;
                            text-align: center;
                            width: 120px;
                            border: none;
                            background-color: rgba(0, 0, 0, 0.074);
                            margin: 0 10px;
                        }
                    }
                }

                >&:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    align-items: start;

                    >input {
                        height: 100%;
                        border: 1px solid var(--whitesmoke-900);
                        border-radius: 3px;
                        width: 450px;
                        padding: 10px 10px;
                    }

                    >span {
                        font-size: 13px;
                        color: rgb(237, 34, 34);
                        margin-top: 2px;
                    }

                    >button {
                        margin-top: 10px;
                        padding: 10px 13px;
                        background: rgb(237, 34, 34);
                        color: white;
                        border-radius: 3px;
                        font-size: 14px;
                        border: none;
                    }
                }
            }
        }

        .download-file-excel {
            width: max-content;
            position: relative;

            button {
                font-size: 14px;
                background-color: var(--dark);
                color: white;
                padding: 6px 12px;
                border: none;
            }

            .list-transact {
                width: 170px;
                position: absolute;
                right: 0%;
                top: 100%;
                padding-left: 0% !important;
                display: flex;
                flex-direction: column;
                background-color: white;
                box-shadow:  0 0 5px rgba(0, 0, 0, 0.108);

                li {
                    width: 100%;
                    font-size: 13px;
                    padding: 5px 0px;
                    text-indent: 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.123);
                    }
                }
            }
        }
    }
}