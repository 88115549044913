@import "../../globals/mixins.scss";

.header-home {
    width: 100%;
    height: 60px;
    background: none;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    top: 0%;
    left: 0%;
    background: white !important;
    border-bottom: 1px solid var(--whitesmoke-500);


    .logo-link {
        margin-left: 2rem;
        display: flex;
        align-items: center;

        img {
            width: 50px;
        }

        @include desktop_md {
            display: none;
        }
    }

    .div-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 85%;

        nav {
            margin-right: 1rem;
            height: 100%;

            .nav-ul {
                display: flex;
                align-items: center;
                height: 100%;

                .nav-li {
                    margin: 0 14px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .link {
                        font-size: 14px;
                        position: relative;
                        color: var(--dark) !important;

                        &::before {
                            content: "";
                            background: var(--dark);
                            height: 2px;
                            width: 0%;
                            position: absolute;
                            bottom: -4%;
                            border-radius: 50px;
                            transition: 0.3s;
                        }
                    }

                    .group-sub-link {
                        display: none;
                        position: absolute;
                        top: 100%;
                        width: 20vw;
                        background-color: white;
                        border: 1px solid var(--whitesmoke-500);
                    }

                    .div-sub-link {
                        padding: 1rem;
                        display: flex;
                        flex-direction: column;

                        .sublink {
                            font-size: 13px;
                            color: var(--dark);
                            position: relative;

                            &::before {
                                content: "";
                                background: var(--dark);
                                height: 2px;
                                width: 0%;
                                position: absolute;
                                bottom: -4%;
                                border-radius: 50px;
                                transition: 0.3s;
                            }

                            &:hover {
                                &::before {
                                    width: 100%;
                                }
                            }
                        }

                        h1 {
                            text-transform: uppercase;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            padding-left: 0%;

                            a {
                                font-size: 14px;
                                color: var(--dark);
                                margin: 7px 0 7px;
                            }
                        }

                    }

                    &:hover {
                        .link {
                            &::before {
                                width: 100%;
                            }
                        }

                        .group-sub-link {
                            display: flex;
                        }
                    }

                    /*  &:nth-child(1) {
                        .group-sub-link {
                            position: absolute;
                            height: 400px;
                            width: 100% !important;
                            left: 0% !important;
                            padding: 2rem;

                            .div-sub-link {
                                margin-right: 5rem;

                                h1 {
                                    font-size: 20px;
                                    font-weight: 500;
                                    margin-bottom: 0.8rem;
                                }
                            }
                        }

                        &:hover {
                            h1 {
                                animation: opa 1s;
                            }

                            .group-sub-link {
                                .div-sub-link {
                                    transition: 0.7s;
                                    animation: migr 1s;

                                    ul {
                                        a {
                                            animation: opa 1s;
                                        }
                                    }
                                }

                                @keyframes migr {
                                    from {
                                        margin-top: 30px;
                                    }

                                    to {
                                        margin-top: 0;
                                    }
                                }


                            }

                            @keyframes opa {
                                0% {
                                    opacity: 0;
                                }

                                50% {
                                    opacity: 0.5;
                                }

                                100% {
                                    opacity: 1;
                                }
                            }
                        }
                    } */

                    /* &:not(:nth-child(1)) {
                        position: relative;

                        .div-sub-link {
                            padding: 1.2rem;
                        }
                    } */
                }
            }

            @include desktop_md {
                display: none;
            }
        }

        .color-link {
            color: var(--dark) !important;
        }

        .div-right {
            display: flex;
            align-items: center;
            margin-left: 3rem;
            height: 100%;

            .btn-create-shop {
                height: 30px;
                padding: 0 10px 0 10px;
                margin-right: 10px;
                background-color: white;
                border: 1px solid var(--dark);
                border-radius: 3px;

                a {
                    color: var(--dark);
                }

                @include desktop_md {
                    display: none;
                }
            }

            .div-lang {
                height: 100%;
                position: relative;

                li {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 13px;
                        margin-right: 10px;
                    }

                    span {
                        font-size: 14px;
                    }
                }

                .div-choose-lang {
                    background: white;
                    border: 1px solid var(--whitesmoke-500);
                    padding: 1rem;
                    width: 13vw;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    display: none;
                    flex-direction: column;

                    a {
                        font-size: 14px;
                        margin: 6px 0 6px;
                    }

                }

                &:hover {
                    .div-choose-lang {
                        display: flex;
                    }
                }

                @include desktop_md {
                    display: none;
                }
            }

            .link-connect {
                font-size: 14px;
            }

            .mg {
                margin: 0 10px;
            }

            .btn-search {
                background: none;
                border: none;

                svg {
                    font-size: 1.3rem;
                }
            }

            .btn-cart {
                margin-right: 2rem;
                font-size: 1.3rem;
                background: none;
                border: none;
                position: relative;

                span {
                    position: absolute;
                    font-size: 16px;
                    left: 100%;
                    border-radius: 50%;
                    height: 22px;
                    width: 22px;
                    top: -30%;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--orange-logo);
                }
            }
        }

        @include desktop_md {
            width: max-content !important;
            margin-left: 0%;

            .div-right {
                margin-left: 0%;
            }
        }
    }

    .div-search {
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.219);
        position: fixed;
        top: 0%;
        left: 0%;
        height: 100vh !important;
        z-index: 1;
        display: flex;
        justify-content: center;

        .block-searchs {
            width: 100%;
            height: 250px;
            background-color: white;
            margin: 0%;

            form {
                display: flex !important;
                margin: 4rem auto;
                width: 50%;
                position: relative;

                .closeSearch {
                    font-size: 3rem;
                    color: var(--dark);
                    position: absolute;
                    right: -20%;
                    top: -50%;
                    cursor: pointer;

                    @include phone {
                        right: 0%;
                    }
                }

                .div-input {
                    width: 100%;
                    margin-top: 3rem;

                    input {
                        width: 100%;
                        height: 50px;
                        border: none;
                        border-bottom: 2px solid var(--whitesmoke-900);
                        font-size: 18px;
                        padding: 0 2rem 0 3rem;
                    }

                    button {
                        position: absolute;
                        left: 0%;
                        top: 60%;
                        background: none;
                        border: none;

                        svg {
                            font-size: 1.5rem;
                            color: var(--dark);
                        }
                    }

                    select {
                        position: absolute;
                        right: 1%;
                        top: 65%;
                        border: none;
                        color: var(--dark);
                        font-size: 16px !important;
                        width: 25% !important;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        /* .category {
                            font-size: 18px;
                            color: var(--dark);
                            cursor: pointer;
                            display: flex;
                            align-items: center;

                            svg {
                                margin-left: 5px;
                                font-size: 1.5rem;
                                color: var(--dark);
                            }
                        }

                        .select-cat-div {
                            display: none;
                            flex-direction: column;
                            position: absolute;
                            top: 100%;
                            background-color: white;
                            box-shadow: 0 0 5px var(--dark);
                            width: 15vw;
                            padding: 2px 0 2px;
                            border-radius: 3px;

                            span {
                                padding-left: 1rem;
                                color: var(--dark);
                                margin: 5px 0 5px;
                                cursor: pointer;
                            }
                        } */

                        .category:hover {
                            .select-cat-div {
                                display: flex;
                            }
                        }
                    }
                }

                @include phone {
                    width: 80%;
                    margin-top: 2rem;
                }

                @include small {
                    width: 90%;

                    .div-input {
                        width: 100%;
                        margin-top: 3rem;
    
                        input {
                            font-size: 14px;
                        }
    
                        button {
                            
                            svg {
                                font-size: 1.2rem;
                            }
                        }
    
                        select {
                            font-size: 14px !important;
                        }
                    }
                }
            }

            @include phone {
                height: 200px;
            }
        }
    }

    &:hover {
        background: white !important;

        .color-link {
            color: var(--dark) !important;
        }
    }

    .icon-nav-mobile {
        font-size: 1.7rem;
        margin-left: 1rem;
        cursor: pointer;
        display: none;

        svg {
            color: var(--dark);
        }

        @include desktop_md {
            display: block !important;
        }
    }

    .div-account-user {
        width: max-content;
        position: relative;
        margin: 0 10px;
        display: block !important;

        p {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 13px;
            margin-bottom: 12px;

            b {
                margin-top: 0% !important;
                font-size: 14px;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .first-lis {
            color: white;
            font-size: 13px;
            cursor: pointer;
        }

        .bv {
            width: 210px;
            padding-top: 1.8rem;
            position: absolute;
            top: 100%;
            right: 0%;
            display: none;

            .box-account-user {
                border: 1px solid var(--whitesmoke-500);
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: white;
                padding: 10px;

                ul {
                    width: 100%;
                    padding-left: 0% !important;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 1rem;
                    padding: 10px 0 10px;
                    border: none;
                    border-top: 1px solid var(--whitesmoke-500);
                    border-bottom: 1px solid var(--whitesmoke-500);

                    a {
                        font-size: 13px;
                        color: var(--dark);
                        margin: 6px 0 6px;
                        display: flex;
                        align-items: center;

                        svg {
                            font-size: 14px;
                            margin-right: 10px;
                        }

                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                }

                .btn-deconnect {
                    width: 100%;
                    font-size: 14px;
                    color: white;
                    background-color: var(--dark);
                    height: 35px;
                    border: none;
                }
            }
        }

        &:hover {
            .bv {
                display: block;
            }
        }

        @include desktop_md {
            display: none !important;
        }
    }

    .mobile {
        position: fixed;
        width: 0%;
        height: 100vh;
        top: 0%;
        left: 0%;
        overflow: hidden;
        z-index: 1;
        background: rgba(255, 255, 255, 0.5);

        .div-nav {
            width: 85% !important;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 1rem 0 0 12px;
            background-color: white;
            position: relative;
            justify-content: start !important;
            align-items: start !important;

            .logo-link-mobile {
                margin-bottom: 5px;

                img {
                    width: 50px;
                }
            }

            .closes {
                font-size: 2.5rem;
                color: var(--dark);
                position: absolute;
                right: 2%;
                top: 2%;
                cursor: pointer;
            }

            ul {
                display: flex;
                flex-direction: column;
                align-items: start;
                padding-left: 0% !important;
                margin-bottom: 1rem;

                a {
                    margin: 7px 0 7px 0 !important;
                    font-size: 14px;
                    color: var(--dark);
                }
            }

            .link-connect {
                padding: 0 1rem 0 1rem;
                height: 32px;
                font-size: 13px;
                margin-bottom: 1rem !important;
                border: 1px solid var(--dark);
                background-color: var(--dark);
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.7s;
                width: 120px;
                color: white !important;
            }

            .btn-create-shop {
                height: 32px;
                padding: 0 1rem 0 1rem;
                margin-right: 10px;
                background-color: white;
                border: 1px solid var(--dark);
                font-size: 13px !important;
                transition: 0.7s;
                margin-bottom: 1rem;
                width: 153px;

                >a {
                    color: var(--dark);
                }

                &:hover {
                    background: whitesmoke;
                }
            }

            .div-account-user {
                margin: 0%;
                display: block !important;

                p {
                    margin-bottom: 0% !important;
                }

                .bv {
                    left: 0% !important;
                    top: 0% !important;
                    width: 250px !important;
                }
            }
        }
    }

    @include desktop_md {
        height: 50px;
    }
}

.header2 {
    width: 100%;
    position: static !important;
    background: white !important;
    align-items: center !important;
    justify-content: start;
    z-index: 1;
    border-bottom: 1px solid var(--whitesmoke-500);

    h2 {
        font-size: 20px;
        font-weight: 500;
        margin-left: 2rem;
    }

    .logo-link {
        margin-left: 1rem;
        padding-top: 6px;

        img {
            width: 40px;
        }

        @include desktop_md {
            display: block;
        }
    }
}

.headAuth {
    height: 70px !important;
    .logo-link  {
        display: block !important;
    }
}