@import "../../../../globals/mixins.scss";

.article-page {
    width: 100%;

    .article {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;

        .content-tabs {
            width: 100%;

            .head-tabs {
                width: 100%;
                background: var(--whitesmoke);
                border-top: 1px solid var(--whitesmoke-500);
                padding: 0px 0 0 5px;

                button {
                    height: 30px;
                    font-size: 13px;
                    color: var(--dark);
                    border: none;
                    padding:  0 12px 0 12px;
                    border-right: 1px solid var(--whitesmoke-500);
                }
            }

            .contents-tabs {
                width: 100%;
                padding-top: 1rem;

                .table-data {
                    tbody {
                        tr {
                            td {
                                width: 15% !important;

                                &:nth-child(1) {
                                    width: 5% !important;
                                }

                                &:last-child {
                                    width: 5% !important;
                                }
                            }
                        }
                    }
                }

                form {
                    width: 92%;
                    background-color: rgb(252, 252, 252);
                    border: 1px solid var(--whitesmoke-500);
                    padding: 10px;

                    .msg-text {
                        font-size: 12px !important;
                        color: rgb(255, 128, 0);
                        background-color: rgba(255, 98, 0, 0.131);
                        width: 93%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 4px;
                        margin-bottom: 10px;
                    }

                    h3 {
                        font-size: 15px;
                        font-weight: 500;
                        margin-bottom: 1rem;
                        text-decoration: underline;
                    }

                    .div-input {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 12px;

                        label {
                            font-size: 13px;
                            color: var(--dark);
                            margin-bottom: 8px;
                        }

                        select,
                        input {
                            text-indent: 5px;
                            height: 30px;
                            width: 100%;
                            background-color: white;
                            color: var(--dark);
                            border-radius: 3px;
                            border: 1px solid var(--whitesmoke-500);
                            padding: 0%;
                        }

                        select {
                            height: 32px;
                        }

                        textarea {
                            border-radius: 3px;
                            width: 98%;
                            padding: 5px;
                            background-color: white;
                            border: 1px solid var(--whitesmoke-500);
                        }
                    }

                    .err {
                        font-size: 12px !important;
                        color: var(--red);
                        margin-top: 5px;
                    }

                    .div-select-file {
                        height: max-content;
                        width: 100%;
                        border: 1px solid var(--whitesmoke-900);
                    }

                    .div-file {
                        cursor: pointer;
                        margin-bottom: 2px;
                        width: 100%;
                        height: 40px;
                        border-bottom: 1px dashed var(--whitesmoke-900);
                        display: flex;
                        flex-direction: column;
                        justify-content: center !important;
                        align-items: center !important;
                        border-radius: 4px;
                        position: relative;
                        padding: 5px 0;

                        input {
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            position: absolute;
                            cursor: pointer;
                        }

                        >svg {
                            fill: var(--dark);
                            font-size: 1.5rem;
                            margin-bottom: 6px !important;
                        }

                        span {
                            margin-top: 0% !important;
                            font-size: 12px !important;
                            color: var(--dark) !important;
                            text-align: center;
                        }
                    }

                    .show-img {
                        padding: 10px;
                        width: 100%;
                    }

                    .images {
                        display: flex;
                        flex-direction: row;

                        img {
                            height: 80px;
                            width: 30%;
                            margin-right: 10px;
                        }
                    }

                    p {
                        font-size: 12px;
                    }
                    >button {
                        height: 28px;
                        width: max-content;
                        padding: 0 1rem 0 1rem;
                        background: var(--dark);
                        color: white;
                        font-size: 14px;
                        margin-top: 14px;
                        border: none;
                    }
                }

                .editor {
                    margin-bottom: 1rem;
                    div {
                        &:last-child {
                            height: 120px;
                        }
                    }
                }
            }

            .add-article {
                width: 100%;
                display: flex;
                flex-direction: column;

                .child {
                    width: 100%;
                    margin-bottom: 2rem;
                }

                .add-categorie {
                    width: 80%;
                    margin-top: 12px;
                }

                @include tablet {
                    flex-direction: column;

                    .child {
                        width: 100%;
                        margin-bottom: 2rem;
                    }

                    .add-categorie {
                        width: 400px;
                    }
                }
            }
        }
    }
}