@import "../../../globals/mixins.scss";

.home-page {
    padding-top: 1rem;

    .section-one {
        width: 100% !important;
        height: 100vh;
        background-size: cover;
        position: relative;
        margin-top: 2rem;

        .slider-img {
            width: 100%;

            .slid {
                height: 100vh !important;
                width: 100% !important;
                background-size: cover;
                position: relative;

                button {
                    margin-bottom: 5rem;
                }

                img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                }

                .slid-child {
                    background: rgba(0, 0, 0, 0.327);
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: end;

                    div {
                        width: 60%;
                        margin-bottom: 5rem !important;
                        margin-right: 4rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: end !important;

                        h1 {
                            width: 100%;
                            text-transform: uppercase;
                            font-size: 4rem;
                            font-weight: 500;
                            color: white;
                            text-align: end !important;
                            margin-right: 0% !important;
                        }

                        p {
                            text-transform: uppercase;
                            font-size: 15px;
                            color: white;
                            margin: 0% !important;
                            width: 100%;
                            text-align: end !important;
                        }
                    }
                }

                img {
                    width: 100%;
                    height: 50% !important;
                }




            }

            .d1 {
                background-size: 100%;
                background: url(../../../assets/images/png/home-img.jpg);
            }

            .d2 {
                background: url(../../../assets/images/png/glamour.jpg);
                background-size: cover;
            }

            .d3 {
                background-size: contain;
                background: url(../../../assets/images/png/shop-img.jpg);
            }
        }

        @include desktop_md {
            height: 75vh;

            .slider-img {
                width: 100%;

                .slid {
                    height: 75vh !important;
                    position: relative;

                    .slid-child {

                        h1 {
                            text-transform: uppercase;
                            font-size: 3rem;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        @include phone {
            height: 65vh;

            .slider-img {

                .slid {
                    .slid-child {

                        div {
                            width: 60%;
                            margin-bottom: 0% !important;
                        }

                        h1 {
                            width: 100%;
                            text-transform: uppercase;
                            font-size: 2.5rem;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        @include small {
            height: 90vh;
            border: 1px solid;

            .slider-img {
                width: 100%;

                .slid {
                    height: 90vh !important;
                    position: relative;

                    .slid-child {
                        justify-content: start;

                        div {
                            width: 90% !important;
                            margin-left: 1rem;
                            justify-content: start;
                        }

                        h1 {
                            text-align: start;
                            text-transform: uppercase;
                            font-size: 2.5rem !important;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }

    .section-two {
        .s2-block1 {
            width: 100%;
            height: 330px;
            display: flex;
            margin: 0rem 0 4.5rem;
            justify-content: space-between;

            .bloc1,
            .bloc2 {
                width: 49%;
                height: 100%;
                background-size: 50%;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .text-div {
                    position: absolute;
                    top: 0%;
                    right: 20%;
                    margin: 5rem 0 0 3rem;

                    h5 {
                        font-size: 14px;
                        text-transform: uppercase;
                        color: white;
                        margin-bottom: 1rem;
                        background-color: var(--dark);
                        padding: 4px 10px 4px 10px;
                        border-radius: 5px;
                        width: max-content;
                        font-weight: 500;
                    }

                    h2 {
                        font-size: 20px;
                        color: var(--dark);
                        margin-bottom: 3rem;
                        font-weight: 500;
                        background-color: white;
                        padding: 5px 1rem 5px 1rem;
                        width: max-content;
                    }

                    a {
                        padding: 8px 1rem 8px 1rem;
                        border-radius: 5px;
                        color: white;
                        background: rgb(177, 102, 84);
                        display: flex;
                        align-items: center;
                        width: max-content;
                        font-size: 14px;

                        svg {
                            margin-left: 1rem;
                            font-size: 1.3rem;
                        }
                    }
                }
            }

            @include phone {
                flex-direction: column;
                height: max-content;

                .bloc1,
                .bloc2 {
                    height: 300px;
                    width: 100%;
                    margin-bottom: 1rem;

                    .text-div {
                        margin-left: 0%;
                        left: 5%;
                    }
                }
            }
        }

        .s2-block2 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .div-left {
                width: 25%;
                padding: 2rem 1rem;
                background: #DD8A0D;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h6 {
                    font-size: 20px;
                    font-weight: 500;
                    color: white;
                }

                h4 {
                    font-size: 30px;
                    color: white;
                    margin: 1rem 0 2rem;
                    font-weight: 500;
                    width: 80%;
                    text-align: center;
                }

                p {
                    color: white;
                    font-size: 14px;
                    text-align: center;
                }

                .btn-sale {
                    width: max-content;
                    height: 50px;
                    font-size: 14px;
                    color: var(--dark);
                    background: whitesmoke;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-items: center;
                    margin-top: 2rem;
                    padding: 0 10px;
                }

                @include desktop_md {
                    display: none;
                }
            }

            .div-slides {
                width: 68%;
                height: max-content;

                .slides-show {
                    width: 100%;
                    height: 100%;

                    button {
                        background-color: black;
                        color: red !important;

                        svg {
                            color: red !important;
                        }
                    }

                    .div-prod {
                        width: 100%;
                        position: relative;

                        .imgs {
                            width: 90%;
                            height: auto;
                            margin: auto;
                            position: relative;

                            >img {
                                width: 100%;
                                height: 100%;
                                margin: auto;
                                border-radius: 10px;
                            }
                        }

                        .info-prod {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0%;
                            left: 0%;
                            background-color: rgba(0, 0, 0, 0.25);
                            display: none;
                            flex-direction: column;
                            justify-content: end;

                            h5 {
                                font-size: 15px;
                                color: var(--dark);
                                margin-left: 10px;
                                font-weight: 500;
                                width: max-content;
                                padding: 5px 10px 5px 10px;
                                border-radius: 5px;
                                background-color: rgba(255, 255, 255, 0.72);
                            }

                            b {
                                font-size: 20px;
                                font-weight: 500;
                                margin: 4px 0 13px 12px;
                                color: white;
                            }

                            button {
                                background-color: black;
                                height: 30px;
                                color: white !important;
                                border-radius: 4px;
                                width: max-content;
                                padding: 0 1rem 0 1rem;
                                margin-bottom: 1rem;
                                margin-left: 12px;
                                border: none;
                                font-size: 15px;
                                cursor: pointer;

                                &:hover {
                                    background: var(--dark);
                                }
                            }
                        }

                        &:hover {
                            .info-prod {
                                display: flex;
                            }
                        }
                    }
                }

                @include desktop_md {
                    width: 100%;
                }
            }
        }
    }

    .section-three {
        width: 100% !important;
        background-color: var(--whitesmoke);
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 2rem 0 2rem;

        h1 {
            font-weight: 500;
            font-size: 35px;
            margin-bottom: 1rem;
            width: 50%;
            color: var(--dark);
            text-align: center;
        }

        p {
            font-size: 14px;
            width: 40%;
            text-align: center;
            color: var(--dark);
        }

        .s3-block-product {
            width: 85%;
            margin: 2rem;
            display: flex;
            justify-content: space-between;

            .s3-card-prod {
                width: 23%;

                .prod-img {
                    width: 100% !important;
                    margin-bottom: 12px;
                    position: relative;
                    overflow: hidden;

                    a {
                        width: 100%;

                        img {
                            width: 100%;
                            height: auto;
                            transition: 1s;
                            border-radius: 0px;
                        }

                        div {
                            position: absolute;
                            top: 0%;
                            left: 0%;
                            width: 100%;
                            height: 100%;
                            background: none;
                            transition: 0.9s;
                        }
                    }

                    &:hover {
                        background: var(--whitesmoke);

                        a {
                            img {
                                transform: scale(1.1);
                            }

                            div {
                                background: rgba(0, 0, 0, 0.144);
                            }
                        }

                    }
                }

                h3 {
                    font-size: 16px;
                    text-transform: uppercase;
                    color: var(--dark);
                    margin-bottom: 5px;
                    font-weight: 500;
                }

                h4 {
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--dark);
                }
            }

            @include phone {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;

                .s3-card-prod {
                    width: 100%;
                }

                .prod-img {
                    width: 100% !important;

                    a {
                        img {
                            height: 250px !important;
                        }
                    }
                }
            }

            @include small {
                .prod-img {
                    width: 100% !important;

                    a {
                        img {
                            height: 130px !important;
                        }
                    }
                }
            }
        }

        @include phone {

            h1,
            p {
                width: 90%;
            }
        }
    }

    .section-four {
        background: none;
    }

    .section-five {
        h1 {
            margin: auto;
        }

        p {
            margin: auto;
            text-align: center;
            width: 50%;
            color: var(--dark);
            font-size: 14px;
        }

        .slider-actuality {
            width: 100%;
            margin-top: 3rem;

            .card-actuality {
                width: 90%;
                display: flex;
                flex-direction: column;

                .cards-act {
                    margin: auto;
                    width: 100%;

                    a {
                        width: 100%;

                        img {
                            width: 100%;
                            height: auto;
                            margin-bottom: 1rem;
                            border-radius: 2px;
                        }
                    }

                    p {
                        font-weight: 500;
                        font-size: 16px;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        color: var(--dark) !important;
                    }

                    h4 {
                        font-weight: 500;
                        font-size: 18px;
                        margin-bottom: 0px;
                    }

                    p {
                        text-align: start;
                        width: 100%;
                        font-size: 13px;
                    }

                    @include phone {
                        a {
                            img {
                                height: 150px;
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    display: none;
                    .cards-act {
                        a {
                            img {
                                height: 200px !important;
                            }
                        }
                    }
                }
            }
        }

        .act {
            display: flex;

            .cards-act {
                a {
                    width: 30%;
                }
            }
        }

        @include small {
            display: flex;
            flex-direction: column;

            h1,
            p {
                width: 100%;
            }
        }
    }

    .section-seven {
        display: flex;
        flex-direction: column;

        .centre-int {
            display: flex;
            justify-content: space-between;

            div {
                display: flex;
                align-items: center;
                box-shadow: 0 0 1px var(--dark);
                padding: 2px;
                border-radius: 6px;
                width: 23%;

                h4 {
                    font-size: 14px;
                    color: var(--dark);
                    font-weight: 400;
                    margin-left: 6px;
                    white-space: nowrap;
                }

                img {
                    width: 50px;
                }

            }

            @include tablet {
                display: grid !important;
                grid-template-columns: repeat(2, 1fr);
                gap: 1.5rem;

                div {
                    width: 95%;
                }
            }

            @include small {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}