.input-phone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30%;
    width: 100% !important;
    margin-top: 5px;

    input {
      margin: 0% !important;
    }

    .dial-code {
      text-indent: 0% !important;
      width: 15% !important;
      height: 100%;
    }

    .input-phone {
      height: 100%;
      width: 80% !important;
    }
  }