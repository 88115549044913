@import "../../../globals/mixins.scss";

.checkout-page {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0rem;

    .alert-popup-valid-shop {
        width: 400px;
        border-radius: 5px;
        background-color: white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
            font-size: 4rem;
            color: green;
            margin-bottom: 1rem;
            margin-top: 10px;
        }

        p {
            width: 90%;
            text-align: center;
        }

        button {
            font-size: 13px;
            border: none;
            width: 80px;
            height: 27px;
            margin-top: 1rem;
            background-color: var(--blue-sombre);
            color: white;
            border-radius: 5px;
        }
    }

    .form-add {
        padding: 1.5rem;
        width: 500px;
        border-radius: 2px;
        background-color: var(--whitesmoke-500);
        position: relative;

        .close-times {
            position: absolute;
            top: 2%;
            right: 3%;
            font-size: 30px;
            color: var(--dark);
            cursor: pointer;
        }

        h3 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 1rem;
        }

        label {
            font-size: 13px !important;
            margin-bottom: 1rem !important;
        }

        .div-inputs {
            p {
                width: 34% !important;

                &:nth-child(3) {
                    width: 25% !important;
                }

                .select-country {
                    height: 33px !important;
                    border-radius: 6px;

                    .selected {
                        span {
                            font-size: 4rem !important;
                            color: var(--dark);

                            &:nth-child(2) {
                                font-size: 13px !important;
                            }
                        }
                    }

                    .options-select {
                        .option {
                            span {
                                &:nth-child(1) {
                                    font-size: 4rem !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .btn-send {
            width: 100%;
            background: var(--dark);
            font-size: 14px;
            color: white;
            height: 40px;
        }

        .div-input {
            .phones {
                input {
                    width: 98% !important;
                }
            }
        }
    }

    .div-left-page {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4rem 2rem 4rem 2rem;

        .title-bloc {
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 1.5rem;

            div {
                height: 1px;
                width: 60%;
                background: var(--whitesmoke-900);
            }
        }

        .form-facturation {
            width: 100%;
            height: max-content;

            .head-form-checkout {
                margin-bottom: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    font-size: 20px;
                }

                a {
                    display: flex;
                    align-items: center;
                    font-size: 14px;

                    svg {
                        font-size: 18px;
                        margin-right: 5px;
                    }
                }
            }

            .ship-addr {
                width: 100%;
                .edit-form-div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: max-content;
                    margin: 0px auto 6px;
                    padding: 0rem 0 1rem;


                    .infos-addr {
                        margin-bottom: 12px;
                        width: 97% !important;
                        background-color: white;
                        padding: 6px;
                        border-radius: 2px;
                        display: flex;
                        justify-content: space-between;
                        align-items: end;
                        position: relative;
                        cursor: pointer;
                        border: 1px solid var(--whitesmoke-500);

                        .check {
                            position: absolute;
                            top: 10%;
                            right: 1%;
                            display: none;

                            svg {
                                color: var(--orange);
                                font-size: 18px;
                            }
                        }

                        p {
                            margin-bottom: 5px;
                        }

                        div {
                            font-size: 12px !important;

                            &:nth-child(2) {
                                display: flex;

                                button {
                                    font-size: 13px;
                                    color: var(--orange);
                                    background: none;
                                    margin-left: 3px;
                                    display: flex;
                                    align-items: center;
                                    border: none;

                                    svg {
                                        font-size: 14px;
                                        margin-right: 10px;
                                    }

                                }
                            }
                        }

                        &:hover {
                            background: rgba(0, 0, 0, 0.115);
                        }
                    }

                    @include phone {
                        .infos-addr {
                            flex-direction: column !important;
                            align-items: flex-start;

                            div {
                                width: 100%;

                                &:nth-child(2) {
                                    margin-top: 8px !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .step {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                button {
                    padding: 0 1.5rem 0 1.5rem;
                    height: 35px;
                    font-size: 15px;
                    background: var(--dark) !important;
                    border: none;
                    color: white;
                    margin-top: 1.5rem;
                    margin-bottom: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        font-size: 18px;
                        margin-left: 5px;
                    }
                }
            }

            p {
                font-size: 14px;
                margin-bottom: 10px;

                a {
                    color: var(--red-color);
                    font-weight: 600;
                }

                input {
                    height: 1rem;
                    width: 20px;
                    margin-right: 5px;
                }
            }

            label {
                margin-bottom: 8px;
                font-size: 14px !important;
                color: var(--link-color);

                span {
                    color: var(--red-color);
                }
            }

            .div-input {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;

                p {
                    width: 48%;
                    display: flex;
                    flex-direction: column;

                    input,
                    select {
                        width: 100%;
                        height: 36px;
                        color: var(--dark);
                        font-size: 14px;
                        text-indent: 6px;
                        border: 1px solid var(--dark);
                    }

                    input {
                        width: 98%;
                    }

                    .select-country {
                        height: 38px;
                        border-radius: 0;
                        border-color: var(--link-color);
                    }

                    textarea {
                        width: 100%;
                        padding: 5px;
                    }

                    .err{
                        font-size: 12px;
                        color: var(--orange);
                    }
                }
            }

            .inputs {
                p {
                    width: 105%;

                    input {
                        width: 99.3%;
                    }
                }
            }

            .msg-succes-div {
                width: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 3rem 0 3rem;

                svg {
                    font-size: 3rem;
                    color: green;
                    margin-bottom: 1rem;
                }

                p {
                    font-size: 14px !important;
                    margin-top: 1rem;
                }
            }
        }
    }

    .loading-pay {
        width: 450px;
        padding: 2rem;
        border-radius: 5px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .msg-succes-done {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .gif-succes{
                width: 100px;
                margin-bottom: 2rem;
            }

            p {
                font-size: 13px;
                text-align: center;
                margin-bottom: 1rem;
            }

            .btn-click {
                margin-top: 1.5rem;
                height: 30px;
                padding: 0 2rem 0 2rem;
                color: white;
                border: none;
                background-color: var(--dark);
            }
        }
    }

    .div-order-summary {
        width: 55%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4rem 0 4rem;

        .div-card-summary {
            background-color: white;
            width: 320px;
            display: flex;
            flex-direction: column;
            position: fixed;
            top: 10%;
            padding: 1rem;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

            .product-item {
                height: 200px;
                width: 95%;
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                background-color: white;
                padding: 7px;

                .cart-item {
                    margin-bottom: 1rem;
                    display: flex;
                    align-items: center;

                    img {
                        width: 70px;
                        margin-right: 1rem;
                    }

                    h3 {
                        font-size: 12px;
                        font-weight: 500;
                        margin-right: 1rem;
                    }

                    h4 {
                        font-size: 13px;
                        font-weight: 500;
                        margin-right: 1rem;

                        sup {
                            font-size: 12px;
                        }
                    }

                    span {
                        background: var(--whitesmoke-900);
                        color: white;
                        padding: 5px 5px 5px 5px;
                        font-size: 12px;
                        border-radius: 50px;
                    }
                }
            }

            p {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                padding: 10px 0 10px;
                border-bottom: 1px solid var(--dark);

                span {
                    font-size: 13px !important;
                }

                b {
                    font-size: 18px;

                    sup {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }

            .text-cond {
                font-size: 12px !important;
                margin: 1rem 0 10px;
                text-align: justify;

                a {
                    color: rgb(109, 175, 255);
                }
            }

            .di {
                display: flex;

                input {
                    height: 1.5rem;
                    width: 18px;
                    margin-right: 5px;
                }

                span {
                    font-size: 13px;
                }
            }

            .btn-valid {
                margin-top: 1.5rem;
                background: var(--dark);
                font-size: 15px;
                color: white;
                height: 40px;
                border: none;
            }
        }
    }


    @include desktop_md {
        flex-direction: column;
        align-items: center;
        height: max-content;

        .div-left-page {
            width: 80%
        }

        .div-order-summary {
            height: max-content;
            position: static !important;
            width: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0% !important;
            margin-bottom: 4rem;
            background: none;

            .div-card-summary {
                height: max-content;
                position: static !important;
            }
        }
    }

    @include phone {
        .div-left-page {
            width: 90%;

            .head-form-checkout {
                flex-direction: column !important;
            }
        }
    }
}