@import "../../../globals/mixins.scss";

.condition-general {
    width: 70%;
    margin: auto;
    margin-bottom: 8rem;
    padding-top: 10rem;

    .title  {
        font-size: 2.5rem;
        margin-bottom: 2rem;
        color:  var(--dark);
        width: 60%;
    }
    p {
        font-size: 14px;
        margin-top: 12px;
        text-align: justify;
    }

    li {
        list-style-type: disc;
    }

    .block-section {
        margin-top: 2rem;
        h1 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 4px;
            color: var(--dark);
        }
    }

    @include phone {
        width: 90%;

        .title {
            font-size: 2rem;
        }
    }
}