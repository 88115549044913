@import "../../../../globals/mixins.scss";

.profil-account {
    width: 100% !important;

    .div1 {
        width: 100%;
        form {
            width: 70%;
            margin: auto;
            #msg-res {
                width: 97%;
                color: var(--orange) !important;
                font-size: 13px;
                margin-bottom: 10px;
                background-color: rgba(255, 183, 0, 0.098);
                font-style: italic;
            }

            .div-input {
                width: 100% !important;
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;

                .span-error {
                    font-size: 13px !important;
                    color: var(--orange);
                    margin-left: 0% !important;
                }

                p {
                    width: 100%;
                    display: flex;
                    align-items: start;
                    position: relative;
                    flex-direction: column;

                    select, input {
                        width: 100%;
                        height: 40px;
                        border-radius: 2px;
                        font-size: 14px;
                        background-color: none;
                        border: 1px solid var(--dark);
                        text-indent: 12px;
                        color: var(--dark)
                    }

                    select {
                        width: 101% !important;
                    }
                }

                @include phone {
                    p {
                        flex-direction: column;
                        align-items: start;

                        label {
                            margin-left: 0% !important;
                            text-align: start;
                        }

                    }

                    .text-img-accept {
                        width: 100%;
                        margin-left: 0%;
                    }
                }
            }

            .inputs {
                display: flex;
                flex-direction: row !important;
                justify-content: space-between !important;

                p {
                    width: 47% !important;
                }
            }

            .btn-update {
                height: 32px;
                width: 120px;
                margin-top: 3rem;
                font-size: 15px;
                border: none;
                background: var(--dark) !important;
                color: white;
                float: right;
                box-shadow: 0 5px 5px var(--box-shadow);

                &:hover {
                    box-shadow: none;
                }
            }
        }

        @include phone {
            form {
                width: 100%;
                padding-left: 0% !important;

                .div-input {
                    width: 95%;
                    padding-left: 0% !important;
                    margin-left: 0% !important;

                    p {
                        width: 100%;
                    }
                }

                .inputs {
                    width: 100% !important;
                }
            }
        }
    }
}