.card-product-list {
    width: 100%;
    display: flex;
    height: 250px;

    .div-img {
        height: auto;
        width: 30%;
        position: relative;
        margin-right: 2rem;

        img {
            width: 100%;
            height: 100%;
        }

        .btn-heart {
            background: rgba(255, 255, 255, 0.555);
            height: 40px;
            width: 40px;
            border-radius: 50%;
            font-size: 1.5rem;
            position: absolute;
            top: 2%;
            right: 2%;
            color: var(--dark);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
        }
    }

    .info-prod-list {
        height: 100%;

        h4 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 2rem;
            color: var(--dark);
        }
        
        p {
            margin-bottom: 1rem;
            color: var(--dark);
        }

        h5 {
            color: var(--dark);
            font-size: 20px;
            font-weight: 600;
        }

        .btn-add-cart {
            width: max-content;
            padding: 0 2rem 0 2rem;
            background: var(--dark);
            color: white;
            font-size: 15px;
            height: 35px;
            border: none;
            margin-top: 2rem;
            cursor: pointer;

            &:hover {
                background-color: black;
            }
        }
    }
}