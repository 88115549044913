@import "../../globals/mixins.scss";

footer {
    background-color: white;
    border-top: 1px solid var(--whitesmoke-500);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .box-news {
        width: 450px;
        border-radius: 10px;
        padding: 2rem 0 2rem;
        background-color: var(--whitesmoke-500);
        position: relative;

        .close {
            font-size: 2rem;
            color: var(--dark);
            position: absolute;
            top: -5%;
            right: 1%;
            cursor: pointer;
        }

        .newsletter {
            margin-top: 0%;
            border: 1px solid;

            form {
                width: 80%;
                height: 33px;
                border-radius: 6px;
                overflow: hidden;
                border: none;

                span {
                    font-size: 13px !important;
                }
            }
        }
    }

    p {
        color: var(--dark);
    }

    .row1,
    .row2 {
        width: 100%;
        margin: auto;
    }

    h2 {
        font-size: 15px;
        color: var(--dark);
        margin-bottom: 5px;
    }

    .row1 {
        width: 85%;
        margin: auto;
        height: 200px;
        display: flex;
        justify-content: space-between;
        margin: 4rem auto 4rem;

        .bar {
            margin: 0 1rem;
            height: 100%;
            width: 1px !important;
            background-color: rgb(196, 196, 196);
        }

        .foot-div1,
        .foot-div2,
        .foot-div3,
        .foot-div4 {
            width: 23%;
        }


        .foot-div1,
        .foot-div2,
        .foot-div3,
        .foot-div4 {
            h2 {
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 1rem;
                text-transform: uppercase;
            }

            ul {

                display: flex;
                flex-direction: column;
                padding-left: 0%;

                a {
                    margin: 6px 0 6px;
                    color: var(--dark);
                    padding: 0 5px 0 0px;
                    font-size: 14px;
                    position: relative;
                    text-transform: capitalize;
                    transition: 0.7s;
                    width: max-content;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 105%;
                        height: 2px;
                        width: 0%;
                        transition: 0.7s;
                        background: var(--dark);
                    }

                    &:hover {
                        &::before {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .foot-div3 {

            div {
                width: 100%;
                display: flex;

                img {
                    width: 70px;
                    height: 70px;
                }
            }
        }

        .foot-div4 {
            p {
                font-size: 13px;
            }
            .group-social {
                display: flex;
                margin-top: 1rem;

                a {
                    margin-right: 13px;
                    svg {
                        color: var(--dark);
                        font-size: 1.2rem;
                    }
                }
            }
        }


        @include desktop_md {
            height: max-content;
            flex-direction: column;
            margin-top: 1.2rem;
            padding-left: 0rem !important;
            width: 100%;
            margin-top: 1rem;

            .foot-div1,
            .foot-div2,
            .foot-div3,
            .foot-div4 {
                margin-left: 1rem;
                margin-bottom: 1rem;
                width: 90% !important;
                background: none !important;

                >h2 {
                    margin-top: 0.3rem;
                }
            }

            .foot-div2 {
                margin-top: 0%;
            }

            .foot-div2,
            .foot-div1,
            .foot-div3 {
                ul {
                    a {
                        margin: 2px 0 2px;
                        color: var(--dark);
                        padding: 0 5px 0 0px;
                        font-size: 14px;
                    }
                }
            }

            .foot-div4 {
                margin-bottom: 0% !important;
                width: 100%;
                padding: 0% !important;
            }

            .newsletter {
                margin-top: 1rem;
                display: block;
                margin-left: 0rem;

                form {
                    input {
                        width: 350px;
                    }
                }
            }
        }

        @include small {
            .newsletter {
                form {
                    input {
                        width: 220px;
                    }
                }
            }
        }
    }

    .newsletter {
        width: 52%;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        margin-top: 3rem;

        >h2 {
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 500;
            color: var(--dark);
            text-align: center;
        }

        >p {
            font-size: 14px;
            margin-bottom: 10px;
            margin-top: 0px !important;
            text-align: center !important;
            width: max-content !important;
        }

        .msg-text {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: var(--orange);
            background-color: rgba(255, 157, 0, 0.176);
        }

        >p {
            display: flex;
            align-items: center;
            width: 100%;
            height: 50px !important;

            >input {
                width: 350px;
                height: 92%;
                font-size: 14px;
                padding: 0 5px 0 5px;
                background: white;
                border: 3px solid var(--dark)
            }

            >button {
                height: 100%;
                background: var(--dark);
                margin-left: 10px;
                font-size: 14px;
                color: white;
                width: 120px;
                border: none;
            }
        }
        >span {
            font-size: 13px;
        }

        @include desktop_md {
            margin-top: 0% !important;
        }

        @include small {
            margin-top: 0% !important;
            width: 90%;

            >p {
                width: 100% !important;
            }
        }
    }

    .div-foot {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid var(--whitesmoke-500);
        margin-top: 2rem;
        width: 100%;

        >p {
            font-size: 13px;

            &:first-child {
                margin-left: 2rem;
            }

            &:last-child {
                margin-right: 2rem;
            }
        }
    }
}

.footer2 {
    padding-top:  0% !important;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center; 
    p {
        font-size: 13px;
    }
}