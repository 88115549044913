.filter-element-div {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.274);
    z-index: 5;
    overflow-y: scroll;

    .close {
        cursor: pointer;
        font-size: 5rem;
        color: white;
        position: absolute;
        top: 2%;
        right: 5%;
    }

    .filters-element {
        width: 350px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        background-color: white;

        .head-filter {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;

            h4 {
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
            }

            button {
                background: none;
                height: 34px;
                color: var(--dark);
                font-size: 2rem;
                border: none;
                margin-top: -5%;
                cursor: pointer;
            }
        }

        .block-filters {
            width: 100%;
            display: flex;
            flex-direction: column;

            h2 {
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
                margin: 1.5rem 0 12px;
                color: var(--dark);
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                svg {
                    font-size: 1.2rem;
                    cursor: pointer;
                }
            }

            .category {
                width: 100%;
                overflow-y: scroll;
                transition: 0.4s;

                &::-webkit-scrollbar {
                    width: 8px;
                    background-color: var(--whitesmoke-500);
                    border-radius: 40px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: var(--whitesmoke-900);
                    border-radius: 40px;
                }

                .box-cat {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-bottom: 12px;

                    p {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        li {
                            font-size: 13px !important;
                            color: var(--dark);
                        }

                        svg {
                            font-size: 1rem;
                            color: var(--dark);
                        }
                    }

                    .sublink {
                        padding-left: 1.2rem;
                        height: max-content;
                        overflow: hidden;

                        li {
                            color: var(--dark);
                            font-size: 14px;
                            margin: 5px 0 5px;
                        }
                    }
                }
            }

            .filter-color {
                width: 100%;
                display: flex;
                align-items: center;
                overflow: hidden;

                div {
                    margin: 0 10px;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    border: 2px solid var(--whitesmoke-500);
                    cursor: pointer;
                    transition: 0.4s;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            .filter-size {
                display: flex;
                align-items: center;
                overflow: hidden;

                div {
                    border: 1px solid var(--whitesmoke-900);
                    font-size: 13px;
                    border-radius: 5px;
                    height: 30px;
                    padding: 0 12px 0 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: 0.7s;
                    margin: 0 5px;

                    &:hover {
                        background: var(--whitesmoke-500);
                    }
                }
            }

            .filter-price {
                overflow: hidden;
                p {
                    width: 90%;
                    margin: auto;
                    font-size: 14px;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;

                    input {
                        width: 60px;
                        font-size: 14px;
                        border: none;
                        background: none;
                    }
                }
            }
        }
    }
}