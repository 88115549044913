@import "../../../globals/mixins.scss";

.promo-page {
    width: 90%;
    margin-bottom: 8rem !important;
    padding-top: 10rem;
    margin: auto;

    h2 {
        margin-left: 0% !important;
        padding-left: 0% !important;
        font-size: 1.5rem;
        font-weight: 500;
    }

    .groups-prod-promo {
        margin-top: 2rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;

        .prod-cards {
            width: 100% !important;
            padding-top: 10px;

            .imgs {
                height: 280px;
                width: 90%;
                margin: auto;
                border-radius: 10px !important;
                overflow: hidden !important;
            }
        }

        @include desktop_md {
            
            grid-template-columns: repeat(3,2fr);

            .prod-cards {
                .imgs {
                    height: 240px;
                }
            }
        }

        @include phone {
            grid-template-columns: repeat(2,2fr);

            
        }

        @include small {
            .prod-cards {
                .imgs {
                    height: 180px;
                }
            }
        }
    }
}