@import "../../../../globals/mixins.scss";

.settings-page {
    width: 100%;
    height: 100%;

    .settings {
        height: 40vh;

        .btn-group {
            width: max-content;
            display: flex;

            a, button {
                border: none;
                height: 30px;
                white-space: nowrap;
                padding: 0 1rem 0 1rem;
                font-size: 13px;
                color: white;
                background: var(--dark);
                margin-right: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            a {
                font-size: 12px;
            }
        }
    }

    .alert {
        width: 400px;
        padding: 1.5rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            font-size: 13px;
            text-align: center;
            width: 100%;
        }

        button {
            font-size: 14px;
            background: var(--dark);
            color: white;
            border: none;
            margin-top: 1rem;
            height: 30px;
            padding: 0 10px 0 10px;
        }
    }
}