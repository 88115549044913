@import "../../../../globals/mixins.scss";

.order-dashboard-buyer {
    width: 100%;
    height: 550px;
    margin-bottom: 4rem;
    border-radius: 10px;

    h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .orders {
        height: 80%;
        border-radius: 6px;
        padding: 12px 12px 0 12px;
        background-color: white;
        border-radius: 6px;

        .table {
            overflow-y: scroll;
            height: 100%;
            margin-top: 12px;

            &::-webkit-scrollbar {
                width: 10px;
                background-color: var(--whitesmoke-500);
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 30px;
                background-color: var(--whitesmoke-900);
            }

            table {
                tbody {
                    td {
                        width: 15% !important;

                        img {
                            width: 150px;
                            height: auto;
                        }

                        .btn-mak {
                            width: max-content;
                            height: 30px;
                            padding: 0 10px;
                            background: rgb(252, 45, 45);
                            color: white;
                            border-radius: 2px;
                        }
                    }
                }
            }
        }

        @include tablet {

            .table-data {
                width: 1000px !important;
            }

        }
    }

    .popup-order {
        width: 600px;
        padding: 1.5rem;
        background-color: white;
        position: relative;

        .close {
            position: absolute;
            top: 0%;
            right: 1%;
            font-size: 1.5rem;
            color: var(--dark);
        }
        .content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                font-size: 13px;
                color: var(--dark);
                margin-bottom: 1rem;
                text-align: center;
            }

            .btn-und {
                color: rgb(38, 38, 245);
                font-size: 15px;
                background: none;
                border: 1px solid rgb(38, 38, 245);
            }

            svg {
                font-size: 2.5rem;
                margin-bottom: 1.5rem;
            }

            .close-btn {
                background: var(--dark);
                color: white;
                width: max-content;
                height: 30px;
            }

            .btn-livre {
                color: white;
                background-color: rgb(252, 45, 45);
                border-radius: 5px;
                width: max-content;
                height: 30px;
                border: none;
            }
        }
    }
}