.sale-dashboard-buyer {
    width: 100%;
    height: 100%;
    
    .div-form-edit {
        width: 400px;
        height: max-content;
        padding: 10px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .close-x {
            height: 30px;
            width: 30px;
            background-color: var(--blue-sombre);
            color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            position: absolute;
            right: -2%;
            top: -5%;
            border: none;
            cursor: pointer;
        }

        h2 {
            width: 100%;
            padding: 8px 0 8px;
            margin-bottom: 2rem;
            font-size: 16px;
            text-align: center;
            background-color: var(--whitesmoke);
            color: var(--dark);
        }

        form {
            width: 90%;
            display: flex;
            flex-direction: column;
            
            h3{
                font-size: 14px;
                margin-bottom: 1rem;
            }

            p {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                label {
                    font-size: 14px;
                    white-space: nowrap;
                    width: 33%;
                    margin-right: 10px;
                    color: var(--dark);
                    text-align: end;
                }

                select, input {
                    width: 70%;
                    height: 32px;
                    border-radius: 3px;
                    border: 1px solid var(--whitesmoke-500);
                    padding-left: 10px;
                    color: var(--dark);
                    background: none;
                }
            }

            button {
                width: max-content;
                white-space: nowrap;
                padding: 0 1rem 0 1rem;
                height: 26px;
                border-radius: 2px;
                color: white;
                margin-top: 1rem;
                border: none;
                background-color: var(--dark);
            }
        }
    }

    .sale {
        .table-data {
            tbody {
                tr {
                    td {
                        width: 15%;

                        img {
                            height: auto;
                            width: 100%;
                        }

                        &:nth-child(1),
                        &:nth-child(5) {
                            width: 6% !important;
                        }
                    }
                }
            }
        }
    }
}