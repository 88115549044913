@import "../../../../globals/mixins.scss";

.adress-account {
    width: 100%;

    h2 {
        font-size: 18px;
        font-weight: 500;
    }

    .block-addr {
        width: 100%;
        display: flex;
        justify-content: space-around;

        .list-adress {
            width: 46%;
            margin-right: 2rem;

            .text-addr {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                margin-top: 2rem;

                svg {
                    fill: var(--whitesmoke-900);
                    font-size: 2rem;
                    margin-bottom: 1rem;
                }
            }

            .ship-addr {
                width: 100%;

                .title-bloc {
                    font-size: 18px;
                    font-weight: 500;
                    border-bottom: 1px solid var(--whitesmoke-900);
                    margin-bottom: 1rem;
                }

                .edit-form-div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: max-content;
                    margin: 0px auto 6px;
                    padding: 0rem 0 1rem;


                    .infos-addr {
                        transition: 0.7s;
                        margin-bottom: 12px;
                        width: 97% !important;
                        background-color: white;
                        padding: 6px;
                        border-radius: 2px;
                        display: flex;
                        justify-content: space-between;
                        align-items: end;
                        position: relative;
                        cursor: pointer;
                        border: 1px solid var(--whitesmoke-500);

                        .check {
                            position: absolute;
                            top: 10%;
                            right: 1%;
                            display: none;

                            svg {
                                color: var(--orange);
                                font-size: 18px;
                            }
                        }

                        p {
                            margin-bottom: 5px;
                        }

                        div {
                            font-size: 12px !important;

                            &:nth-child(2) {
                                display: flex;

                                button {
                                    font-size: 13px;
                                    color: var(--orange);
                                    background: none;
                                    margin-left: 3px;
                                    display: flex;
                                    align-items: center;
                                    border: none;

                                    svg {
                                        font-size: 14px;
                                        margin-right: 10px;
                                    }

                                }
                            }
                        }

                        &:hover {
                            background: rgba(0, 0, 0, 0.085);
                        }
                    }

                    @include phone {
                        .infos-addr {
                            flex-direction: column !important;
                            align-items: flex-start;

                            div {
                                width: 100%;

                                &:nth-child(2) {
                                    margin-top: 8px !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .form-addr {
            width: 48%;

            form {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .step {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    h4 {
                        margin-bottom: 2rem;
                        font-size: 18px;
                        font-weight: 500;
                        width: 100%;
                        border-bottom: 1px solid var(--whitesmoke-900);
                    }

                    button {
                        padding: 0 1.5rem 0 1.5rem;
                        height: 35px;
                        font-size: 15px;
                        background: var(--dark) !important;
                        border: none;
                        color: white;
                        margin-top: 1.5rem;
                        margin-bottom: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            font-size: 18px;
                            margin-left: 5px;
                        }
                    }
                }

                p {
                    font-size: 14px;
                    margin-bottom: 10px;

                    a {
                        color: var(--red-color);
                        font-weight: 600;
                    }

                    input {
                        height: 1rem;
                        width: 20px;
                        margin-right: 5px;
                    }
                }

                label {
                    margin-bottom: 8px;
                    font-size: 14px !important;
                    color: var(--link-color);

                    span {
                        color: var(--red-color);
                    }
                }

                .div-input {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1rem;

                    p {
                        width: 48%;
                        display: flex;
                        flex-direction: column;

                        input,
                        select {
                            width: 100%;
                            height: 36px;
                            color: var(--dark);
                            font-size: 14px;
                            text-indent: 6px;
                            border: 1px solid var(--dark);
                        }

                        input {
                            width: 98%;
                        }

                        .select-country {
                            height: 38px;
                            border-radius: 0;
                            border-color: var(--link-color);
                        }

                        textarea {
                            width: 100%;
                            padding: 5px;
                        }

                        span {
                            font-size: 12px;
                            color: var(--orange);
                        }
                    }
                }

                .inputs {
                    p {
                        width: 105%;

                        input {
                            width: 99.3%;
                        }
                    }
                }

                .msg-succes-div {
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 3rem 0 3rem;

                    svg {
                        font-size: 3rem;
                        color: green;
                        margin-bottom: 1rem;
                    }

                    p {
                        font-size: 14px !important;
                        margin-top: 1rem;
                    }
                }
            }
        }

        @include phone {
            flex-direction: column;

            .list-adress,
            .form-addr {
                width: 100%;
            }

            .list-adress {
                height: 300px;
                margin-bottom: 2rem;

                h4 {
                    margin-bottom: 0% !important;
                }

                .edit-form-div {
                    overflow-y: scroll;
                    height: 250px !important;
                    background: rgb(250, 250, 250);
                }
            }
        }
    }
}