.shop-admin-page {
    width: 100%;
    height: 100%;
    
    .div-form-edit {
        width: 400px;
        height: max-content;
        padding: 10px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .close-x {
            height: 30px;
            width: 30px;
            background-color: var(--blue-sombre);
            color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            position: absolute;
            right: -2%;
            top: -5%;
            border: none;
            cursor: pointer;
        }

        h2 {
            width: 100%;
            padding: 8px 0 8px;
            margin-bottom: 2rem;
            font-size: 16px;
            text-align: center;
            background-color: var(--whitesmoke);
            color: var(--dark);
        }

        form {
            width: 90%;
            display: flex;
            flex-direction: column;
            
            h3{
                font-size: 14px;
                margin-bottom: 1rem;
            }

            p {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                label {
                    width: 33%;
                    margin-right: 10px;
                    color: var(--dark);
                    text-align: end;
                }

                select, input {
                    width: 70%;
                    height: 32px;
                    border-radius: 6px;
                    border: none;
                    padding-left: 10px;
                    color: var(--dark);
                    background: var(--blue-clair);
                }
            }

            button {
                width: max-content;
                white-space: nowrap;
                padding: 0 1rem 0 1rem;
                height: 26px;
                border-radius: 2px;
                color: white;
                margin-top: 1rem;
                border: none;
                background-color: var(--orange);
            }
        }
    }

    .shop-admin {
        .btn-group-action {
            width: max-content;
            margin-bottom: 15px;
            display: flex;
            align-items: center;

            >button {
                font-size: var(--dark);
                font-size: 13px;
                border: 1px solid var(--dark);
                border-radius: 2px;
                height: 40px;
                background: none;
                display: flex;
                align-items: center;
                padding: 0 10px 0 10px;

                svg {
                    font-size: 15px;
                    margin-right: 5px;
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }
            }
        }
        .table-data {
            tbody {
                tr {
                    td {
                        width: 15%;

                        img {
                            height: 100px;
                            width: 90%;
                        }

                        &:nth-child(1),
                        &:nth-child(5) {
                            width: 6% !important;
                        }

                        &:last-child {
                            width: 20% !important;

                            button {
                                &:nth-child(2) {
                                    color: var(--red);
                                }

                                &:nth-child(1) {
                                    color: green;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .stats-div {
        background-color: white;
        padding: 1.5rem;
        width: 550px;
        position: relative;

        .close {
            cursor: pointer;
            font-size: 2rem;
            color: var(--dark);
            position: absolute;
            top: 0%;
            right: 1%;
        }

        h3 {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 2rem;
        }

        .block-stats {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .div-c {
                width: 28%;
                height: 80px;
                border-radius: 3px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 10px;

                b {
                    font-size: 1.8rem;
                    margin-bottom: 2px;
                    color: white;
                }

                input {
                    font-size: 1.8rem;
                    font-weight: 600;
                    color: white;
                    background: none;
                    border: none;
                }

                p {
                    font-size: 14px;
                    color: white;
                }

                &:nth-child(1) {
                    background-color: rgb(245, 54, 54);
                }
                &:nth-child(2) {
                    background-color: rgb(42, 108, 250);
                }

                &:nth-child(3) {
                    background-color: rgb(13, 194, 255);
                }
            }
        }
    }
}