@import "../../../globals/mixins.scss";

.dashboard-seller-home {
    width: 100%;
    height: 100vh !important;
    overflow: hidden;

    .open-sub-menu {
        height: max-content !important;
    }

    .card-share {
        background-color: white;
        padding: 1rem;
        width: 400px;
        position: relative;

        .close {
            position: absolute;
            top: 0%;
            right: 2%;
            font-size: 2rem;
            color: var(--dark);
            cursor: pointer;
        }

        p {
            font-size: 13px;
            margin-top: 1rem;
        }

        .socio-share {
            margin-top: 1rem;
            display: flex;
            align-items: center;

            button {
                margin-right: 10px;
                background: none;
                border: none;
            }
        }
    }

    .div-select-store {
        padding: 1rem;
        width: 400px;
        background-color: white;
        border-radius: 5px;
        position: relative;

        .close-x {
            position: absolute;
            font-size: 2rem;
            top: 0%;
            right: 2%;
            cursor: pointer;
        }

        h1 {
            font-size: 14px;
            font-weight: 500;
            width: max-content;
            margin: 1rem auto 1rem;
        }

        .store-div {
            width: 80%;
            height: 100px;
            border: 1px solid var(--whitesmoke-500);
            border-radius: 5px;
            overflow-y: scroll;
            margin: 5px auto 2rem;

            &::-webkit-scrollbar {
                width: 6px;
                border-radius: 30px;
                background-color: var(--whitesmoke-500);
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--whitesmoke-900);
                border-radius: 30px;
            }

            p {
                padding: 6px;
                border-bottom: 1px solid var(--whitesmoke-500);
                display: flex;
                justify-content: space-between;
                cursor: pointer;

                svg {
                    color: var(--orange);
                    font-size: 1rem;
                }

                &:hover {
                    background-color: var(--whitesmoke-500);
                }
            }
        }

        button {
            background-color: var(--red-gradient2);
            font-size: 13px;
            color: white;
            border: none;
            height: 28px;
            width: 40px;
            border-radius: 5px;
        }
    }

    .main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: var(--whitesmoke);


        .main-center {
            padding-bottom: 2rem;
            height: max-content;
            display: flex;
            justify-content: space-between;

            .nav-bar {
                width: 200px;
                height: 100vh;
                display: flex;
                align-items: center;
                flex-direction: column;
                padding-top: 0px;
                background: var(--deep-brown);
                box-shadow: 0 5px 5px var(--dark);
                position: relative;

                .close-mobile-nav {
                    background: var(--dark);
                    font-size: 2rem;
                    color: white;
                    cursor: pointer;
                    position: absolute;
                    right: 2%;
                    z-index: 1;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    display: none;
                    align-items: center;
                    justify-content: center;
                }

                .logo {
                    width: 100%;
                    display: flex;
                    height: 58px;
                    align-items: center;
                    justify-content: center;
                    background: var(--deep-brown) !important;
                    padding: 5px 0 5px;

                    img {
                        width: 50px;
                    }
                }

                .user-profils {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    h3 {
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: 5px;
                        color: var(--dark);
                    }

                    svg {
                        font-size: 3rem;
                        color: var(--whitesmoke-900);
                        margin-bottom: 0px;
                    }

                    img {
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                    }
                }

                .shop {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 6px 0 6px;
                    margin-top: 0px;
                    background: none;
                    border-top: 1px solid var(--whitesmoke-900);
                    border-bottom: 1px solid var(--whitesmoke-900);

                    h4 {
                        font-size: 14px;
                        font-weight: 500;
                        width: max-content;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: white;
                    }

                    svg {
                        font-size: 25px;
                        margin-right: 8px;
                    }
                }

                .btn-change-shop {
                    border: none;
                    border-radius: 30px;
                    white-space: nowrap;
                    padding: 4px 10px 4px 10px;
                    margin-top: 1rem;
                    color: var(--dark);
                    background: whitesmoke;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .nav {
                    width: 100%;
                    margin-top: 0px;
                    padding-top: 20px;

                    .bar {
                        height: 1px;
                        width: 100%;
                        background-color: var(--whitesmoke-500);
                        margin: 5px 0 5px;
                    }

                    .nav-ul {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        cursor: pointer;
                        width: 100%;
                        padding: 0%;
                        padding-bottom: 0% !important;
                        overflow: hidden;

                        .link-p {
                            color: white;
                            padding: 12px 0 12px 10px;
                            width: 97%;
                            height: max-content;
                            display: flex;
                            align-items: center;
                            position: relative;
                            justify-content: space-between;

                            span {
                                display: flex;
                                align-items: center;
                                font-size: 13px;

                                svg {
                                    font-size: 17px;
                                    color: var(--whitesmoke-500);
                                }
                            }

                            svg {
                                font-size: 15px;
                                margin-right: 10px;
                            }

                            &:hover {
                                background-color: rgba(0, 0, 0, 0.386);
                                &::before {
                                    display: block;
                                }
                            }
                        }

                        .sub-ul {
                            height: 0;
                            overflow: hidden;
                            width: 100% !important;
                            padding: 0%;
                            padding-left: 0%;
                            background-color: rgb(83, 80, 80);

                            li {
                                padding: 7px 0 7px 12px;
                                font-size: 13px;
                                width: 94%;
                                margin-bottom: 0% !important;
                                color: white;
                            }
                        }
                    }
                }

                .footer {
                    position: absolute;
                    bottom: 1%;

                    p {
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                @include tablet {
                    transition: 0.1s;
                    width: 0;
                    z-index: 1000;
                    overflow: hidden;

                    .close-mobile-nav {
                        display: flex;
                    }
                }
            }

            .body {
                width: 100%;
                height: 98%;
                position: relative;
                padding-bottom: 0rem;

                .head {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: auto;
                    width: 99%;
                    height: 40px;
                    background: white;
                    padding: 0px 10px 0px 10px;
                    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

                    h3 {
                        font-size: 14px;
                        font-weight: 500;
                        color: var(--deep-brown);
                        font-style: italic;
                    }

                    li {
                        font-size: 13px;
                        color: var(--deep-brown);
                        display: flex;
                        align-items: center;

                        &:nth-child(1) {
                            svg {
                                font-size: 18px;
                                margin-right: 12px;
                                cursor: pointer;
                                display: none;
                            }
                        }

                        span {
                            margin: 0 5px;
                        }

                        svg {
                            font-size: 18px;
                        }
                    }

                    .link-store {
                        height: 30px;
                        width: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: white;
                        position: absolute;
                        top: 2%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        clip-path: polygon(0 0, 100% 0, 94% 100%, 8% 100%);

                        a {
                            font-size: 13px;
                            color: var(--orange);
                            text-decoration: none;
                        }
                    }

                    .block-right {
                        margin-right: 1rem;
                        display: flex;
                        align-items: center;
                        .btn-share-link {
                            color: var(--deep-brown);
                            border: none;
                            border-left: 1px solid var(--deep-brown);
                            border-right: 1px solid var(--deep-brown);
                            background: none;
                            margin-right: 1rem !important;
                            padding: 3px 1rem 2px 1rem;
                            display: flex;
                            align-items: center;

                            svg {
                                font-size: 14px;
                                margin-right: 10px;
                            }
                        }

                        .user-prof {
                            display: flex;
                            align-items: center;

                            div {
                                display: flex;
                                flex-direction: column;

                                span {
                                    font-size: 13px !important;
                                    width: 100px;
                                    margin-right: 8px;

                                    input {
                                        width: 100%;
                                        font-size: 13px;
                                        font-weight: lighter;
                                        border: none;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-align: end;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }

                            button {
                                margin-left: 1rem;
                                border: none;
                                color: var(--deep-brown);
                                font-size: 14px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: none;
                                padding-left: 1rem;
                                border-left: 1px solid var(--deep-brown);

                                svg {
                                    margin-right: 5px;
                                    font-size: 14px !important;
                                }
                            }

                            img {
                                margin-left: 10px;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                            }

                            svg {
                                font-size: 2rem;
                                color: var(--deep-brown);
                            }
                        }
                    }

                    @include phone {
                        li {
                            width: 35%;

                            svg {
                                font-size: 2rem !important;
                            }

                            h3 {
                                font-size: 14px;
                                width: 100%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                margin-right: 0% !important;
                            }
                        }
                    }

                    @include tablet {
                        width: 100%;
                        border-bottom-left-radius: 0%;
                        border-bottom-right-radius: 0%;

                        li {
                            &:nth-child(1) {
                                svg {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                .content-body {
                    overflow-y: scroll;
                    width: 100%;                        
                    height: 78vh !important;
                    height: 95vh !important;
                    padding-bottom: 5rem;

                    .block-content {
                        padding-bottom: 3rem;
                        width: 96%;
                        margin: 1rem auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .slider-prods {
                            width: 100%;
                            height: 120px;
                            margin-bottom: 1rem;
                        }

                        @include tablet {
                            width: 93%;
                        }
                    }
                }

                @include tablet {
                    width: 100%;
                }
            }
        }
    }
}