@import "../../../globals/mixins.scss";

.about-page {
    padding-top: 1rem;
    width: 100%;

    h5 {
        font-size: 18px;
        font-weight: 400;
        color: var(--orange-logo);
        margin-bottom: 12px;
        text-transform: uppercase;
    }

    h2 {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    p {
        font-size: 14px;
    }

    .section-one-about {
        width: 100% !important;
        height: 100vh;
        background-size: cover;
        position: relative;
        margin-top: 2rem;

        .slid {
            height: 100vh !important;
            width: 100% !important;
            background-size: cover;
            position: relative;

            button {
                margin-bottom: 5rem;
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
            }

            .slid-child {
                background: rgba(0, 0, 0, 0.327);
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: start;

                div {
                    width: 48%;
                    margin-bottom: 5rem !important;
                    margin-right: 4rem;
                    margin-left: 4rem;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: start !important;

                    h1 {
                        width: 100%;
                        font-size: 4rem;
                        font-weight: 500;
                        color: white;
                        text-align: start !important;
                        margin-right: 0% !important;
                    }

                    p {
                        text-transform: uppercase;
                        font-size: 15px;
                        color: white;
                        margin: 0% !important;
                        width: 60%;
                        text-align: start !important;
                        position: relative;
                        padding-left: 15px;

                        &::before {
                            content: "";
                            height: 100%;
                            width: 2px;
                            background: white;
                            position: absolute;
                            left: 0%;
                        }
                    }
                }
            }

            img {
                width: 100%;
                height: 50% !important;
            }
        }

        .d1 {
            background: url(../../../assets/images/about-image.webp);
            background-size: 100% !important;
            background-repeat: no-repeat;
        }

        @include desktop_md {
            height: 75vh;

            .slid {
                height: 75vh !important;
                position: relative;

                .slid-child {
                    div {
                        width: 90%;

                        p {
                            width: 100%;
                        }

                        h1 {
                            margin-bottom: 1rem;
                            font-size: 2.8rem;
                        }
                    }
                }
            }
        }

        @include phone {
            height: 65vh;

            .slid {
                .slid-child {

                    div {
                        width: 60%;
                        margin-bottom: 0% !important;
                    }

                    h1 {
                        width: 100%;
                        text-transform: uppercase;
                        font-size: 2.5rem;
                        font-weight: 500;
                    }
                }
            }
        }

        @include small {
            height: 90vh;
            border: 1px solid;

            .slid {
                height: 90vh !important;
                position: relative;

                .slid-child {
                    justify-content: start;

                    div {
                        width: 90% !important;
                        margin-left: 1rem;
                        justify-content: start;

                        h1 {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    }

    .section-two-about {
        display: flex;
        justify-content: space-between;

        img {
            width: 55%;
            height: auto;
        }

        .div-text {
            width: 40%;

            .stats {
                margin-top: 1.5rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .chiffre {
                    width: 40%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    h3 {
                        font-size: 3rem;
                        font-weight: 400;
                        margin-bottom: 10px;
                    }

                    p {
                        width: 100% !important;
                        text-align: center;
                        font-size: 13px;
                        text-transform: uppercase;
                    }
                }

                p {
                    width: 45%;
                }
            }
        }

        @include desktop_md {
            flex-direction: column;

            img {
                width: 100%;
                margin-bottom: 2rem;
            }

            .div-text {
                width: 100%;
            }
        }
    }

    .section-three-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--whitesmoke-300);
        padding: 3rem 0 3rem;
        width: 100% !important;

        .div-text {
            width: 60%;
            display: flex;
            justify-content: space-between;
        
            @include phone {
                width: 90%;

                .left-text,
                .right-text {
                    width: 47%;
                }
            }

            @include small {
                flex-direction: column;

                .left-text,
                .right-text {
                    width: 100%;
                }
            }
        }

        .left-text,
        .right-text {
            width: 45%;

            p {
                margin-bottom: 1rem;
                text-align: justify;
            }

            li {
                font-size: 14px;
                list-style-type: disc !important;
                margin-bottom: 6px;
            }
        }
    }

    .section-four-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--whitesmoke-300);
        padding: 4rem 0 4rem;
        width: 100% !important;

        .div-flex {
            display: flex;
            justify-content: space-between;
            margin-top: 3rem;

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 30%;
                margin: 0 5rem;

                svg {
                    font-size: 3rem;
                    margin-bottom: 1rem;
                    color: var(--dark);
                }

                p {
                    font-size: 17px;
                    color: var(--dark);
                    text-align: center;
                }

                @include small {
                    svg {
                        font-size: 2rem;
                    }

                    p {
                        font-size: 13px;
                    }
                }
            }

            @include small {
                flex-direction: column;

                div {
                    width: 90%;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}