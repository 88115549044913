@import "../../globals/mixins.scss";

.title-tabs {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid var(--deep-brown);
    height: 45px;
    background-color: none;
    margin-top: 5px;
    margin-bottom: 1.2rem;
    color: var(--dark);
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        margin-right: 5px;
        display: flex;
        align-items: center;

        b {
            font-size: 20px;
            font-weight: 500;
            color: var(--dark);
            font-family: 'cambria';
        }

        .bs {
            height: 20px;
            width: 1px;
            background: var(--dark);
            margin: 0 1rem;

        }


        &:nth-child(2) {
            
            span {
                font-size: 12px;
                font-style: italic;
                color: var(--orange);
                margin-left: 1rem;
            }
        }

        @include phone {
            flex-direction: column;
            align-items: start;

            .bs {
                margin-left: 0% !important;
            }
            &:nth-child(1) {
                span {
                    margin-left: 0% !important;
                    width: 100%;
                }
            }
        }
    }

    .groups-btn {
        display: flex;
        align-items: center;
        padding: 0;
        height: 25px;
        margin-right: 1rem;

        button {
            margin-left: 10px;
            background: none;
            font-size: 13px;
            color: var(--dark);
            border: none;
            display: flex;
            align-items: center;
            position: relative;

            svg {
                font-size: 18px;
            }

            &:nth-child(2) {
                padding-left: 13px;
                border-left: 1px solid var(--dark);

                svg {
                    margin-right: 6px;
                }

                span {
                    padding: 5px;
                    background-color: red;
                    border-radius: 50%;
                    height: 20% !important;
                    color: white;
                    border: 2px solid white;
                    font-size: 12px;
                    position: absolute;
                    top: -40% !important;
                    left: 50%;
                }
            }
        }

        .btn-notify {
            position: relative;
            margin-right: 15px;
            cursor: pointer;

            svg {
                font-size: 1.1rem;
                margin-top: 10px;
            }

            .nb-notify {
                padding: 5px;
                background-color: var(--red);
                font-size: 12px;
                color: white;
                position: absolute;
                top: 0%;
                left: 50%;
                height: 20%;
                border-radius: 50%;
                border: 2px solid white;
            }

            @keyframes anim {
                0% {
                    transform: rotateZ(180deg)
                }

                10% {
                    transform: rotateY(0deg);
                }

            }

            .box-notify-div {
                display: none;
                width: 300px;
                height: 150px;
                z-index: 1;
                position: absolute;
                right: 0;
                background-color: white;
                box-shadow: 0 0 10px var(--dark);
                border-radius: 5px;
                overflow-y: scroll;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    background-color: var(--whitesmoke-500);
                    width: 10px;
                    border-radius: 30px;
                }

                &::-webkit-scrollbar-thumb {
                    width: 5px;
                    background-color: var(--whitesmoke-900);
                    border-radius: 30px;
                }

                p {
                    padding: 5px;
                    width: 90%;
                    margin: auto;
                    font-size: 12px;
                    border-bottom: 1px solid var(--whitesmoke-900);
                }
            }

            &:hover {
                .box-notify-div {
                    display: block;
                }
            }
        }

        .btn-share-link {
            color: white;
            background-color: var(--red);
            border-radius: 5px;
            padding: 4px 1rem 4px 1rem;
        }

        @include small {
            .btn-notify {
                display: none;
            }

            button {
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }

    .card-share {
        width: 400px;
        padding: 1rem;
        border-radius: 5px;
        background-color: white;
        position: relative;

        p {
            margin-top: 12px;
        }

        .close {
            font-size: 2rem;
            color: var(--dark);
            position: absolute;
            top: -5%;
            right: 1%;
            cursor: pointer;
        }

        .socio-share {
            width: max-content;
            display: flex;
            justify-content: space-between;
            margin: 1.3rem auto;

            button {
                background-color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 12px;
                color: var(--dark);
                border: none;
                margin: 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    margin-bottom: 5px;
                    width: 30px;
                }
            }

            .copy-link {
                font-size: 15px;
                color: var(--blue-sombre);
            }
        }
    }
}