@import "../../../../globals/mixins.scss";

.profil-dashboard-buyer {
    width: 100%;
    height: 100%;

    .block-form {
        padding: 10px 0 10px;
        margin-top: 12px;
        width: 100%;
        height: max-content;
        border-radius: 6px;
        background-color: white;
        display: flex;
        flex-direction: column;
        padding-bottom: 12px;

        .block-div1 {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include tablet {
                align-items: center;
                flex-direction: column !important;
            }
        }

        form {

            h3 {
                font-size: 18px;
                font-weight: 500;
                color: red;
            }
            #msg-res {
                width: 97%;
                color: var(--orange) !important;
                font-size: 12px;
                margin-bottom: 10px;
                font-style: italic;
            }

            .div-input {
                width: 95%;
                display: flex;
                flex-direction: column;
                padding: 12px 12px 12px;

                .image-profil {
                    width: 100px;
                    height: 130px;
                    margin-left: 2rem;
                }

                .span-error {
                    font-size: 13px;
                    color: var(--orange);
                    margin-left: 32%;
                }

                .text-img-accept {
                    font-size: 12px;
                    color: var(--dark);
                    width: 40%;
                    margin-left: 20%;
                }

                p {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    position: relative;

                    label {
                        font-size: 13px;
                        width: 210px;
                        text-align: end;

                        span {
                            color: red;
                            font-size: 16px;
                            display: none;
                        }
                    }

                    b {
                        font-size: 13px;
                        font-weight: 500;
                    }

                    input {
                        margin-left: 1.5rem;
                        width: 40%;
                        height: 35px;
                        border-radius: 2px;
                        background-color: none;
                        border: 1px solid var(--whitesmoke-900);
                        padding: 0 10px 0 10px;
                        color: var(--dark)
                    }

                    .select {
                        background-color: var(--whitesmoke-500);
                        border: none;
                        width: 52%;
                        height: 31px;
                        border-radius: 5px;
                        margin-left: 1.5rem;
                        margin-right: 0;
                        z-index: 0;
                    }

                    .select-country {
                        width: 42% !important;
                        height: 32px !important;
                        background: none;
                        border-radius: 2px;
                        margin-left: 1.5rem !important;
                        border: 1px solid var(--whitesmoke-900);
                        
                        @include phone {
                            width: 100% !important;
                            margin-left: 0% !important;
                        }
                    }

                    span {
                        font-size: 13px !important;
                        margin-left: 10px;
                    }

                    .file-img {
                        width: 300px;
                        height: 60px;
                        border-radius: 5px;
                        background-color: rgba(82, 82, 255, 0.125);
                        margin-left: 1.5rem;
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        span {
                            text-align: center;
                            width: 100%;
                            font-size: 12px !important;
                            word-wrap: break-word;
                        }

                        svg {
                            font-size: 20px;
                            margin-bottom: 6px;
                        }

                        input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            opacity: 0;
                        }

                        @include phone {
                            width: 200px;
                            margin-left: 0%;
                        }

                    }

                    .img-names{
                        width: 15%;
                        overflow-wrap: break-word;
                    }

                    .role {
                        margin-top: 0%;
                        margin-left: 1.5rem;
                    }

                    .cover-img {
                        width: 15%;
                        margin-left: 1rem;
                    }

                    >textarea {
                        margin-left: 1.5rem;
                        width: 375px;
                        height: 100px;
                    }

                    .btn-click {
                        font-size: 13px;
                        border: none;
                        color: var(--orange);
                        background: none;
                        margin-left: 20%;
                        cursor: pointer;
                    }

                    .change-pwd-divs {
                        width: 400px;
                        position: relative;
                        background-color: white;
                        border-radius: 10px;
                        padding: 3rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        p {
                            text-align: center;
                            margin-bottom: 10px;
                        }

                        .closes {
                            font-size: 2rem;
                            color: var(--dark);
                            position: absolute;
                            right: 0%;
                            top: -25%;
                            cursor: pointer;
                        }
                    }
                }

                @include phone {
                    p {
                        flex-direction: column;
                        align-items: start;

                        label {
                            margin-left: 0% !important;
                            text-align: start;
                        }

                        input {
                            width: 94%;
                            margin-left: 0% !important;
                        }
                    }

                    .text-img-accept {
                        width: 100%;
                        margin-left: 0%;
                    }
                }
            }

            .div-file {
                width: max-content;
            }

            .btn-update {
                height: 32px;
                border-radius: 5px;
                width: 120px;
                margin-top: 3rem;
                font-size: 15px;
                border: none;
                background: var(--colorrs) !important;
                color: white;
                float: right;
                box-shadow: 0 10px 10px var(--box-shadow);

                &:hover {
                    box-shadow: none;
                }
            }
        }

        .div1,
        .div2 {
            width: 90%;

            @include tablet {
                width: 95%;
                margin-bottom: 4rem !important;
                margin-left: 0% !important;
                margin-right: 0% !important;
            }
        }
    }

    .block-div2 {
        width: 100%;
        padding: 10px 0 10px;
        background-color: white;
        margin-top: 1.5rem;
        border-radius: 6px;

        h3 {
            width: 97%;
            margin: auto;
        }

        .block-div-addr {
            width: 97%;
            margin: 10px auto;

            .div-addrs {
                width: 100%;

                .addr {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: 6px 0 6px;
                    margin-bottom: 1rem;

                    &:nth-child(odd) {
                        background-color: var(--whitesmoke);
                    }

                    div {
                        width: max-content;
                        margin-left: 10px;

                        b {
                            font-size: 15px;
                        }

                        p {
                            margin-bottom: 5px;
                        }
                    }

                    button {
                        margin-right: 10px;
                        background: none;
                        font-size: 13px;
                        color: var(--orange);
                        border: none;

                        svg {
                            font-size: 15px;
                        }
                    }
                }
            }

            .btn-add-addr {
                width: 100%;
                padding: 10px 0 10px;
                font-size: 14px;
                background-color: #ff770f20;
                border: none;
                color: var(--orange);
            }
        }
    }
}