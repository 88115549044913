.popup-content {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}