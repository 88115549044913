@import "../../../globals/mixins.scss";

.search-page {
    width: 90%;
    margin: auto;
    padding-top: 8rem;
    padding-bottom: 8rem;

    h1 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 2rem;
    }

    .products {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 1rem;
        margin-bottom: 5rem;

        @include desktop_md {
            grid-template-columns: repeat(4,1fr);
        }

        @include phone {
            grid-template-columns: repeat(3,1fr);
        }

        @include small {
            grid-template-columns: repeat(2,1fr);
        }
    }
}