.spin-anim2 {
    height: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg {
        svg {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 4rem;
            color: var(--whitesmoke-900);
            animation: spin infinite 3s linear;
        }

        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
    
            to {
                transform: rotate(360deg);
            }
        }
    }
}