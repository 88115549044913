@import "../../../globals/mixins.scss";

.articles-page {
    padding-bottom: 10rem;
    .section-one-art {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 3rem;
        margin-bottom: 0%;
        width: 85%;

        p {
            font-size: 14px;
            color: var(--dark);
        }

        .head-arts {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 2rem;


            h5 {
                font-size: 15px;
                font-weight: 600;
                text-transform: uppercase;
                color: var(--whitesmoke-900);
            }

            h1 {
                font-size: 1.5rem;
                margin: 1rem 0 1rem;
            }

            p {
                font-size: 14px;
                text-align: center;
            }
        }

        .body-article {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            p {
                margin-bottom: 0rem;
            }

            .first-img {
                width: 70%;
                height: auto;
                margin-bottom: 1rem;
                margin-top: 1rem;
            }

            .imgs {
                width: 100% !important;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                margin: 1rem 0 1rem;

                img {
                    height: auto;
                    width: 300px;
                    margin: 0 2rem;
                }

                .orther  {
                    display: flex;
                    justify-content: space-between;
                    img {
                        height: auto;
                        width: 300px;
                    }
                }
            }

            li {
                list-style-type: disc;
            }
        }

        .footer {
            border-bottom: 1px solid var(--whitesmoke-500);
            border-top: 1px solid var(--whitesmoke-500);
            padding: 1rem 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;

            p {
                display: flex;
                align-items: center;
                margin: 0 12px;

                b {
                    margin-left: 10px;
                }

                &:nth-child(1) {
                    border-right: 1px solid var(--whitesmoke-500);
                    padding-right: 1.5rem;
                }

                li {
                    margin: 0 6px;
                    a {
                        display: none;
                        svg {
                            color: var(--dark) !important;
                        }
                    }
                }
            }
        }
    }

    .btnNextPrev {
        width: 85%;
        margin: 10px auto;
        border-bottom: 1px solid var(--whitesmoke-500);
        display: flex;
        justify-content: center;
        padding: 1rem 0;

        .prev, 
        .next {
            width: 40%;
            display: flex;
            flex-direction: column;
            margin: 0 2rem;

            h3 {
                font-size: 15px;
                font-weight: 500;
                margin-top: 8px;
                word-break: break-all;
            }

            a {
                display: flex;
                align-items: center;
                font-size: 15px;
                cursor: pointer;

                svg {
                    font-size: 18px;
                    font-weight: 700;
                }
            }
        }

        .next {
            align-items: start;
            span {
                svg {
                    margin-left: 6px;
                }
            }
        }

        .prev {
            align-items: end;
            span {
                svg {
                    margin-right: 6px;
                }
            }
        }
    }

    .comment-div {
        width: 100%;
        padding: 3rem 0 3rem;
        form {
            margin: 1rem auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 600px;

            #msg-form {
                color: var(--orange) !important;
                height: 40px;
                width: 100%;
                display: none;
                align-items: center;
                justify-content: center;
                background-color: rgba(255, 0, 0, 0.122);
            }

            p {
                font-size: 14px;
                color: var(--dark);
                text-align: center;
            }

            .div-field {
                width: 100%;
                margin: 1.8rem 0 1rem;

                textarea {
                    width: 95%;
                    padding: 10px;
                    height: 100px;
                    border: 2px solid var(--whitesmoke-500);
                }

                span {
                    font-size: 13px;
                    color: rgb(255, 67, 30);
                }
            }
            .btn-send {
                width: 200px;
                border: none;
                font-size: 16px;
                background: var(--dark);
                height: 40px;
                color: white;

                &:hover {
                    background: black;
                }
            }

            @include tablet {
                width: 100%;
            }
        }
    }

    .related-posted {
        border-top: 1px solid var(--whitesmoke-500);
        padding-top: 1.5rem;
        margin: auto;
        width: 85%;

        h2 {
            font-size: 2rem;
            font-weight: 500;
            margin: auto;
            width: max-content;
            margin-bottom: 1.5rem;
        }

        .slider-actuality {
            width: 100%;
            margin-top: 3rem;

            .act2 {
                display: grid !important;
                flex-direction: column;
                align-items: center;
            }

            .card-actuality {
                width: 90%;
                display: flex;
                flex-direction: column;

                .cards-act {
                    margin: auto;
                    width: 90%;

                    a {
                        width: 100%;

                        img {
                            width: 100%;
                            margin-bottom: 1rem;
                            border-radius: 2px;
                        }
                    }

                    p {
                        font-weight: 500;
                        font-size: 16px;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        color: var(--dark) !important;
                    }

                    h4 {
                        font-weight: 500;
                        font-size: 18px;
                        margin-bottom: 8px;
                    }

                    p {
                        text-align: start;
                        width: 100%;
                        font-size: 13px;
                    }

                    @include phone {
                        a {
                            img {
                                height: 150px;
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    display: none;
                    .cards-act {
                        a {
                            img {
                                height: 200px !important;
                            }
                        }
                    }
                }
            }
        }

        .act {
            display: none ;
        }

        .act2 {
            display: flex !important;

            .card-actuality {
                width: max-content;
                .cards-act {
                    width: 31%;
                }
            }
        }

        @include small {
            display: flex;
            flex-direction: column;

            .slider-actuality {
                display: none;
            }

            h1,
            p {
                width: 100%;
            }

            .act {
                display: flex;
                flex-direction: column;
                width: 100%;

                .card-actuality {
                    width: 100%;
                    margin-bottom: 4rem;

                    .cards-act {
                        width: 100%;

                        a {
                            img {
                                height: 250px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}