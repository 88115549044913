.popup-content {
    overflow-y: scroll;
  }
  
  .form-create-store1 {
    margin-top: 0rem !important;
    width: 65%;
    height: 70vh;
    background-color: white;
    padding: 1.5rem;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .closes {
      font-size: 3rem;
      color: var(--link-color);
      position: absolute;
      top: 0%;
      right: 2%;
      cursor: pointer;
    }
  
    h2 {
      font-size: 1.2rem;
      font-weight: 400;
      text-align: center;
      text-decoration: underline;
    }
  
    p {
      margin: 0%;
    }
  
    form {
      width: 92%;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        margin-bottom: 2.5rem;
      }
  
      .step {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
  
        button {
          padding: 0 1.5rem 0 1.5rem;
          height: 35px;
          font-size: 15px;
          background: var(--dark) !important;
          border: none;
          color: white;
          margin-top: 1.5rem;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
  
          svg {
            font-size: 18px;
            margin-left: 5px;
          }
        }
      }
  
      p {
        font-size: 14px;
        margin-bottom: 10px;
  
        a {
          color: var(--red-color);
          font-weight: 600;
        }
  
        input {
          height: 1rem;
          width: 20px;
          margin-right: 5px;
        }
      }
  
      label {
        margin-bottom: 8px;
        font-size: 13px !important;
        color: var(--link-color);
  
        span {
          color: var(--red-color);
        }
      }
  
      .div-input {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
  
        p {
          width: 48%;
          display: flex;
          flex-direction: column;
  
          input,
          select {
            width: 100%;
            height: 32px;
            color: var(--dark);
            font-size: 14px;
            text-indent: 6px;
            border: 1px solid var(--dark);
          }

          input {
            width: 98%;
          }

          select {
            height: 37px;
          }
  
          .select-country {
            height: 35px;
            border-radius: 0;
            border-color: var(--link-color);
          }

          textarea {
            width: 100%;
            padding: 5px;
          }

          .err {
            font-size: 12px;
            color: var(--orange);
          }
        }
      }
  
      .step2 {
        width: 100%;
  
        .div {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .quiz-choice {
            display: flex;
            flex-direction: column;
            align-items: center;
  
            span {
              margin-bottom: 10px;
            }
  
            p {
              display: flex;
              align-items: center;
              justify-content: center;
  
              span {
                margin: 0 1rem 0 3px;
              }
            }
          }
  
          .choice-market {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 1rem;
  
            div {
              display: flex;
              align-items: center;
              margin-bottom: 1.5rem;
  
              p {
                display: flex;
                align-items: center;
                margin: 0 10px;
              }
            }
  
            p {
              display: flex;
              align-items: center;
              margin-top: 1rem;
  
              textarea {
                height: 40px;
                width: 250px;
                margin-left: 10px;
              }
            }
          }
        }
  
        .last-p {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 1.5rem !important;
  
          select {
            width: 400px;
            height: 35px;
            font-size: 14px;
            color: var(--link-color);
            border: 1px solid rgb(55, 55, 55);
          }
        }
      }
  
      .msg-succes-div {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3rem 0 3rem;
  
        svg {
          font-size: 3rem;
          color: green;
          margin-bottom: 1rem;
        }
  
        p {
          font-size: 14px !important;
          margin-top: 1rem;
        }
      }
    }
  }