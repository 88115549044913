@import "../../globals/mixins.scss";
.cart-display {
    position: fixed;
    overflow: hidden;
    height: 100vh;
    top: 0%;
    right: 0%;
    transition: 0.2s;
    background-color: rgba(255, 255, 255, 0.603);
    z-index: 10000;

    .cart-box {
        width: 400px;
        height: 100%;
        float: right;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.295);

        @include phone {
            width: 100%;
        }

        .head-cart {
            width: 100%;
            height: 60px;
            background-color: rgb(233, 233, 233);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2rem;

            h4 {
                margin-left: 1rem;
                font-size: 1.1rem;
                color: var(--dark);
                font-weight: 500;
                position: relative;

                input {
                    width: 20px;
                    border: none;
                    background: none;
                    font-size: 15px;
                    position: absolute;
                    top: -5%;
                    left: 110%;
                }
            }

            .close {
                font-size: 2rem;
                color: var(--dark);
                margin-right: 1rem;
                cursor: pointer;
            }
        }

        .cart-content {
            width: 95%;
            height: 65%;
            overflow-y: scroll;

            .card-prod-cart {
                display: flex;
                width: 98%;
                margin-bottom: 1rem !important;

                img {
                    width: 120px;
                    height: auto;
                    margin-right: 1rem;
                }

                .infos-prod-cart {
                    width: 75%;
                    position: relative;

                    .close {
                        position: absolute;
                        top: 0%;
                        right: 0;
                        font-size: 1.5rem;
                        color: var(--dark);
                        cursor: pointer;
                    }

                    h4 {
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }

                    .caract-img {
                        font-size: 12px;
                        color: var(--dark);
                    }

                    .quantity {
                        width: 100%;
                        margin-top: 1px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between !important;

                        .qty {
                            display: flex;
                            align-items: center;
                            border: 1px solid var(--whitesmoke-900);
                            padding: 0 10px;

                            button {
                                font-size: 14px;
                                color: var(--dark);
                                background: none;
                                border: none;
                            }

                            input {
                                width: 20px;
                                border: none;
                                font-size: 14px;
                                margin: 0 2px;
                                text-align: center;
                            }
                        }

                        b{
                            font-size: 18px;
                            color: var(--dark);
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .cart-foot {
            width: 90%;
            margin-top: 1.2rem;
            display: flex;
            flex-direction: column;

            .subprice {
                width: 100%;
                display: flex;
                font-size: 13px;
                justify-content: space-between;

                b {
                    font-size: 20px;
                    font-weight: 500;
                }
            }

            button {
                margin-top: 1.2rem;
                width: 100%;
                background-color: var(--dark);
                height: 40px;
                color: white;
                border: none;
                font-size: 15px;

                &:hover {
                    background: black;
                }
            }
        }
    }
}