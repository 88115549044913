@import "../../../globals/mixins.scss";

.DocumentationPage {
    display: flex;

    &-sidebar {
        width: 190px;
        height: 100vh;
        background-color: rgb(48, 48, 48);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;

        @include phone {
            display: none;
        }
    }

    &-infos {
        font-size: 14px;
        margin-bottom: 20px;

        >a {
            color: rgb(42, 42, 254);
        }
    }

    &-logo {
        width: 50px;
        height: auto;
        margin: 6px 0;
    }

    &-menu {
        width: 90%;
        display: flex;
        flex-direction: column;
        
        &:hover {
            overflow-y: scroll;
        }

        >li {
            margin: 6px 0;
            >a {
                font-size: 13px;
                color: whitesmoke;
            }
        }
    }

    &-menu2 {
        margin-bottom: 50px;
        display: none;
        >h2 {
        width: 100%;
        font-size: 20px;
        font-weight: 500;
        color: whitesmoke;
        display: flex;
        align-items: center;
        background-color: #f8b43d;
        padding: 4px 0;
        text-indent: 10px;
        margin-bottom: 20px;
        margin-top: 15px;
        }

        >li {
            margin-top: 20px;
            margin-left: 18px;
            list-style-type: decimal;
            >a {
                font-size: 14px;
                color: rgb(48, 48, 48);
            }
        }

        @include phone {
            display: block;
        }
    }

    &-rubrique {
        font-size: 16px;
        font-weight: 500;
        color: whitesmoke;
        width: 85%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 4px 7px black;
        background-color: #f8b43d;
        padding: 4px 10px;
        margin-bottom: 20px;
        margin-top: 15px;

        >svg  {
            font-size: 1rem;
        }
    }

    &-body {
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        margin-left: 180px;
        width: 100%;

        @include phone {
            margin-left: 0;
        }
    }

    &-h1 {
        font-size: 30px;
        font-weight: 600;
        width: 100%;
        font-weight: 500;
        color: whitesmoke;
        background-color: #f8b43d;
        padding: 4px 0;
        margin-bottom: 20px;
        margin-top: 15px;
        text-align: center;
    }
}

.BlockContaint {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;

    >img {
        width: 600px;
        height: auto;
        margin-top: 20px;

        @include phone {
            width: 100%;
        }
    }

    &-title {
        font-size: 16px;
        margin-bottom: 10px;
        color: #ee9e13;
    }

    &-description {
        font-size: 14px;
        text-align: justify;
        line-height: 40px;

        >b {
            text-transform: capitalize;
            font-size: 16px;
        }
    }

    &-imgs {
        display: flex;
        justify-content: space-around;
        margin-top: 50px;

        >img {
            width: 48%;
            height: auto;
        }
        @include phone {
            flex-direction: column;

            >img {
                width: 100%;
                margin-bottom: 10px;
            }
        }
        
    }
}