@import "../../globals/mixins.scss";

.head-product {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .page-name {
        font-size: 14px;
        color: var(--dark);
        width: 100%;

        input {
            width: 80% !important;
            font-size: 14px;
            background: none;
            border: none;
        }
    }

    .filters-div {
        display: flex;
        align-items: center;

        .btn {
            background: none;
            height: 35px;
            padding: 0 1rem 0 1rem;
            font-size: 15px;
            color: var(--dark);
            border: none;
            cursor: pointer;
            border-left: 1px solid var(--whitesmoke-900);

            &:nth-child(1) {
                margin-right: 0;
                border-left: none;

                svg {
                    font-size: 1rem;
                }
            }

            svg {
                font-size: 2rem;
                color: var(--dark);

                @include phone {
                    font-size: 1.5rem;
                }
            }
        }
    }
}