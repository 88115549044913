@import "../../../globals/mixins.scss";

.faq-page {
    .section-one-faq {
        width: 100% !important;
        height: 100vh;
        background-size: cover;
        position: relative;
        margin-top: 2rem;

        .slid {
            height: 100vh !important;
            width: 100% !important;
            background-size: cover;
            position: relative;

            button {
                margin-bottom: 5rem;
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
            }

            .slid-child {
                background: rgba(0, 0, 0, 0.327);
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: start;

                div {
                    width: 48%;
                    margin-bottom: 5rem !important;
                    margin-right: 4rem;
                    margin-left: 4rem;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: start !important;

                    h1 {
                        width: 100%;
                        font-size: 4rem;
                        font-weight: 500;
                        color: white;
                        text-align: start !important;
                        margin-right: 0% !important;
                    }

                    p {
                        text-transform: uppercase;
                        font-size: 15px;
                        color: white;
                        margin: 0% !important;
                        width: 60%;
                        text-align: start !important;
                        position: relative;
                    }
                }
            }

            img {
                width: 100%;
                height: 50% !important;
            }
        }

        .d1 {
            background-size: 100%;
            background: url(../../../assets/images/faq.jpg);
            background-repeat: no-repeat;
        }

        @include desktop_md {
            height: 75vh;

            .slid {
                height: 75vh !important;
                position: relative;

                .slid-child {
                    div {
                        width: 90%;

                        p {
                            width: 100%;
                        }

                        h1 {
                            margin-bottom: 1rem;
                            font-size: 2.8rem;
                        }
                    }
                }
            }
        }

        @include phone {
            height: 65vh;

            .slid {
                .slid-child {

                    div {
                        width: 60%;
                        margin-bottom: 0% !important;
                    }

                    h1 {
                        width: 100%;
                        text-transform: uppercase;
                        font-size: 2.5rem;
                        font-weight: 500;
                    }
                }
            }
        }

        @include small {
            height: 90vh;
            border: 1px solid;

            .slid {
                height: 90vh !important;
                position: relative;

                .slid-child {
                    justify-content: start;

                    div {
                        width: 90% !important;
                        margin-left: 1rem;
                        justify-content: start;

                        h1 {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
    }

    .section-two-faq {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .div-quiz {
            width: 70%;
            margin: 2rem auto;
            display: flex;
            justify-content: space-between;

            h3 {
                font-size: 1.8rem;
                font-weight: 400;
            }

            .div-quizs {
                width: 55%;
                display: flex;
                flex-direction: column;

                div {
                    border-bottom: 1px solid var(--whitesmoke-500);
                    height: 50px;
                    overflow: hidden;
                    transition: 0.3s;

                    li {
                        display: flex;
                        align-items: center;
                        columns: var(--dark);
                        font-size: 14px;
                        font-weight: 500;
                        cursor: pointer;
                        height: 100%;

                        svg {
                            color: var(--dark);
                            font-size: 1.2rem;
                            margin-right: 10px;
                        }
                    }
                    p {
                        margin-top: 1rem;
                        font-size: 14px;

                        a {
                            color: var(--dark);
                            position: relative;
                            width: max-content;

                            &::before {
                                transition: 0.3s;
                                content: "";
                                position: absolute;
                                background-color: var(--dark);
                                width: 0%;
                                height: 2px;
                                top: 105%;
                                left: 0%;
                            }

                            &:hover {
                                &::before {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                .open {
                    padding: 1.5rem 0 1.5rem;
                    height: max-content;

                    li {
                        height: max-content;
                    }
                }
            }

            @include phone {
                flex-direction: column;
                width: 90%;
                margin: auto;
                margin-bottom: 4rem;

                h3 {
                    margin-bottom: 1rem;
                }

                .div-quizs {
                    width: 100%;
                }
            }
        }
    }

    .section-three-faq  {
        width: 100%;
        padding: 3rem 0 3rem;
        background-color: var(--whitesmoke-300);

        .div-block {
            width: 80%;
            margin: auto;

            h5 {
                font-size: 16px;
                font-weight: 400;
                color: var(--orange-logo);
                margin-bottom: 1rem;
                position: relative;
                width: max-content;

                &::before {
                    content: "";
                    position: absolute;
                    width: 50px;
                    height: 2px;
                    background: var(--orange-logo);
                    top: 100%;
                    left: 100%;
                }
            }

            div {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    font-size: 1.5rem;
                    font-weight: 400;
                    width: 50%;
                }

                a {
                    height: 50px;
                    padding: 0 2rem 0 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--dark);
                    color: white;
                }
            }

            @include small {
                width: 90%;

                div {
                    width: 100%;
                    align-items: start;
                    flex-direction: column;

                    h3 {
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}