@import "../../globals/mixins.scss";

.select-country {
    margin-bottom: 0% !important;
    margin-left: 0% !important;
    width: 100%;
    height: 35px;
    background-color: none;
    border: 1px solid var(--whitesmoke-900);
    border-radius: 2px;
    position: relative;
    padding: 0% !important;
    cursor: pointer;

    .selected {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0px 0 0px ;
        position: relative;

        .arrow {
            font-size: 17px;
            right: 1.5%;
            position: absolute;

        }

        .selected-item-options {
            width: auto;
            display: flex !important;
            flex-direction: row !important;
            align-items: center;
            background: none;

            i {
                font-size: 14px;
                margin: 0 5px 0 3px !important;
            }
            span {
                width: 100% !important;
                margin-left: 5px;
                height: 20px;
                width: 30px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px !important;
                color: var(--dark);

                input{
                    height: max-content !important;
                    width: 100%;
                    border: none !important;
                }
            }
        }
    }

    .options-select {
        margin-left: 0% !important;
        display: none;
        background-color: white;
        border: 1px solid var(--whitesmoke-500);
        position: absolute;
        top: 100%;
        left: 0%;
        width: 100%;
        height: 150px;
        overflow-y: scroll;
        flex-direction: column;
        padding-left: 0%;
        z-index: 1;

        .search-country-div {
            width: 95%;
            display: flex;
            align-items: center;
            position: relative;
            margin: 10px auto 10px;

            svg {
                position: absolute;
                left: 2%;
                top: 23%;
                font-size: 14px;
            }
            input {
                width: 100% !important;
                height: 24px !important;
                background: none;
                border: 1px solid var(--dark);
                padding-left: 1.8rem;
                font-size: 12px !important;
                border-radius: 5px;
            }
        }

        li {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 3px 0 3px !important;
            color: var(--link-color) !important;
            cursor: pointer;

            &:hover {
                background-color: var(--whitesmoke);
            }

            i {
                font-size: 10px;
                margin: 0 8px 0 4px;
            }
            span {
                font-size: 12px;
                color: var(--dark);
            }
        }
    }

    &:hover {
        .options-select {
            display: flex;
        }
    }
    @include phone {
        .options-select {
            width: 60vw;
        }
    }
}