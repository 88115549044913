@import "../../globals/mixins.scss";

    .order-cart {
        width: 28%;
        height: max-content;
        background-color: white;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
        padding-bottom: 10px;

        .end-p {
            text-align: center;
        }
        >h2 {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            padding: 6px 0 6px;
            color: white;
            background: var(--whitesmoke-900);
            margin-bottom: 1.2rem;
        }

        .infos-order {
            width: 100%;
            padding: 0% !important;
            margin-bottom: 1rem;

            p {
                width: 90%;
                margin: auto;
                font-size: 13px;
                margin-bottom: 1rem;
                display: flex;
                align-items: center;

                span {
                    white-space: nowrap;
                    width: max-content;
                }

                input {
                    border: none;
                    background: none;
                    width: 32% !important;
                    font-size: 16px;
                    font-weight: 500;
                    margin-left: 10px;
                }


                &:last-child {
                    width: 90%;
                    margin-top: 1.5rem;

                    button {
                        border-radius: 1px;
                        margin: 1rem auto !important;
                        width: 100%;
                        height: 40px;
                        font-size: 16px;
                        background: var(--dark);
                        color: white;
                        border: none;
                    }
                }
            }
        }

        @include tablet {
            width: 400px;
        }

        @include phone {
            width: 100%;
        }
    }