@import "../../../globals/mixins.scss";

.contact-page {
    .contact-div-flex {
        height: max-content;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .img {
            padding-top: 6rem;
            width: 50%;
            height: 100%;
            background-color: none;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        .form-contact {
            padding-top: 10rem;
            padding-bottom: 10rem;
            width: 50%;

            .head {
                width: 80%;
                display: flex;
                justify-content: space-between;
                margin: auto;
                margin-bottom: 2rem;

                div {
                    width: 47%;

                    h3 {
                        font-size: 1.5rem;
                        font-weight: 400;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }

            form {
                margin-left: 0% !important;
                margin: 1rem auto !important;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 80%;

                #msg-form {
                    color: var(--orange) !important;
                    height: 40px;
                    width: 100%;
                    display: none;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(255, 0, 0, 0.122);
                }

                p {
                    font-size: 14px;
                    color: var(--dark);
                    text-align: center;
                }

                span {
                    font-size: 13px;
                    color: var(--orange);
                }

                .div-field {
                    width: 100%;
                    margin: 1.8rem 0 1rem;

                    textarea {
                        width: 94%;
                        padding: 10px;
                        height: 140px;
                        border: 2px solid var(--whitesmoke-500);
                    }
                }

                .div-fields {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 1.5rem;

                    p {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        align-items: start;

                        input {
                            font-size: 14px;
                            width: 93.5%;
                            padding: 0 10px 0 10px;
                            border: 2px solid var(--whitesmoke-500);
                            height: 50px;
                        }
                    }
                }

                .infos {
                    margin: 1.5rem 0 2rem;
                    display: flex;
                    align-items: center;

                    input {
                        height: 45px;
                        width: 20px;
                        margin-right: 1rem;
                    }
                }

                .btn-send {
                    width: 200px;
                    border: none;
                    font-size: 16px;
                    background: var(--dark);
                    height: 40px;
                    color: white;

                    &:hover {
                        background: black;
                    }
                }

                @include tablet {
                    width: 100%;
                }
            }
        }

        @include phone {
            .img {
                display: none;
            }

            .form-contact {
                width: 90%;
                margin: auto;

                .head {
                    width: 90%;
                }
            }
        }

        @include phone {

            .form-contact {
                .head {
                    flex-direction: column;
                    width: 90%;

                    div {
                        width: 100%;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}