@import "../../../globals/mixins.scss";

.cart-page {
    width: 88%;
    margin: 5rem auto;

    h1 {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .cart-container {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        .head-cart {
            background: whitesmoke;
            padding: 10px;
            margin-bottom: 1rem;
            margin-bottom: 1.5rem;
            a {
                font-size: 14px;
                color: var(--dark);
                display: flex;
                align-items: center;

                svg {
                    font-size: 16px;
                    margin-right: 5px;
                }
            }
        }
        .cart-item {
            width: 66%;
            height: 500px;
            border: 1px solid var(--whitesmoke-500);
            padding: 1rem;
            overflow-y: scroll;

            .item-box-cart {
                display: flex;
                width: 98%;
                margin-bottom: 1rem;

                &:nth-child(even) {
                    padding-top: 1rem;
                    border-top: 1px solid var(--whitesmoke-500);
                }

                img {
                    width: 120px;
                    height: auto;
                    margin-right: 1rem;
                }

                .infos-prod-cart {
                    width: 75%;
                    position: relative;

                    .close {
                        position: absolute;
                        top: 0%;
                        right: 0;
                        font-size: 1.5rem;
                        color: var(--dark);
                        cursor: pointer;
                    }

                    h4 {
                        font-size: 15px;
                        font-weight: 500;
                        margin-bottom: 1rem;
                    }

                    .caract-img {
                        font-size: 13px;
                        color: var(--dark);
                    }

                    .quantity {
                        width: 100%;
                        margin-top: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between !important;

                        .qty {
                            display: flex;
                            align-items: center;
                            height: 30px;
                            border: 1px solid var(--whitesmoke-900);
                            padding: 0 10px;

                            button {
                                font-size: 14px;
                                color: var(--dark);
                                background: none;
                                border: none;
                            }

                            input {
                                width: 20px;
                                border: none;
                                font-size: 14px;
                                margin: 0 2px;
                                text-align: center;
                            }
                        }

                        b{
                            font-size: 18px;
                            color: var(--dark);
                            font-weight: 500;
                        }
                    }
                }

                @include phone {
                    flex-direction: column;

                    img {
                        width: 100px !important;
                        margin-bottom: 10px;
                    }

                    .infos-prod-cart {
                        width: 100%;
                    }
                }
            }
        }
    }

    @include desktop_md {
        flex-direction: column;

        .cart-container {
            flex-direction: column;

            .cart-item {
                margin-bottom: 2rem;
                width: 95%;
            }
        }
    }
}