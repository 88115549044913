@import "../../../globals/mixins.scss";

.account-page {
    width: 90%;
    background-color: white;
    margin: 5rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-page {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 3rem;
        margin-top: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--whitesmoke);
        height: 50px;
        width: 100%;
        position: relative;

        .menu-icon{
            color: var(--dark);
            font-size: 1.5rem;
            display: none;
            cursor: pointer;
        }

        @include desktop_md {
            .menu-icon {
                display: block;
                position: absolute;
                top: 50%;
                left: 4%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .account-container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .box-menu {
            width: 200px;
            height: max-content;
            border-right: 1px solid var(--whitesmoke-500);
            border-left: 1px solid var(--whitesmoke-500);
            display: flex;
            flex-direction: column;
            align-items: center;
            background: white;

            .close {
                position: absolute;
                font-size: 2.5rem;
                color: var(--dark);
                right: 1%;
                cursor: pointer;
                display: none;
            }

            .user-head {
                width: 90%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 1rem;
                border-bottom: 1px solid var(--whitesmoke-500);

                svg {
                    font-size: 3rem;
                    margin-bottom: 1rem;
                    color: var(--whitesmoke-500);
                }

                h3 {
                    font-size: 14px !important;
                    font-weight: 600;
                    text-align: center;
                    width: 80%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .nav-menus {
                width: 90%;
                margin-top: 1rem;
                margin-bottom: 1rem;

                .menu-lists {
                    padding-left: 0% !important;
                    display: flex;
                    flex-direction: column !important;

                    a {
                        font-size: 14px;
                        white-space: nowrap;
                        color: var(--dark) !important;
                        height: 45px;
                        display: flex;
                        align-items: center;
                        position: relative;

                        svg {
                            font-size: 16px;
                            margin-right: 12px;
                            color: var(--dark);
                        }

                        &::before {
                            content: "";
                            background: var(--dark);
                            height: 2px;
                            width: 0%;
                            position: absolute;
                            bottom: -4%;
                            border-radius: 50px;
                            transition: 0.3s;
                        }

                        &:hover {
                            &::before {
                                width: 100% !important;
                            }
                        }
                    }
                }

                .btn-disconnect {
                    width: 100%;
                    background: none;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    color: white;
                    height: 35px;
                    margin-top: 1rem;
                    background-color: black;
                    cursor: pointer;
                    transition: 0.4s;

                    svg {
                        font-size: 18px;
                        margin-right: 12px;
                    }

                    &:hover {
                        background: var(--dark);
                    }
                }
            }

            @include desktop_md {
                width: 0px;
                overflow: hidden;
                position: absolute;
                top: 0%;
                left: 0%;
                z-index: 1000;

                .close {
                    display: block;
                }
            }
        }

        .account-page-content{
            width: 80%;

            @include desktop_md {
                width: 100%;
            }
        }
    }
}