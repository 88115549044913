:root {
    --brown-color: #474246;
    --deep-brown: #453B3B;
    --text-color: #453B3B;
    --whitesmoke: #f3f3f3;
    --whitesmoke-500: #dddddd;
    --whitesmoke-300: #F2F2F2;
    --whitesmoke-900: #857c7c;
    --dark: #1d2327;
    --orange: #D45D01;
    --orange-logo: #f8b43d;
    --blue: #5454ff;
    --colorrs: #2196f0;
    --blue-200: #008cff;
    --red: #f0212e;
    --blue-sombre: #112C56;
    --orange-transparent: #e0610025;
    --red-transparent: #e9050521;
    --orange2: #fb7815;
    --box-shadow: rgba(0, 0, 0, 0.3);
    --red-gradient2: #112C56;
    --red-gradient: linear-gradient(167.2deg, #f80d1d 9%, rgba(238, 106, 115, 0.69));
    --orange-gradient: linear-gradient(111deg, #D45D01, #F5AD49 118.54%);
    --smoke-gradient: linear-gradient(180deg, #ebebeb, 20%, #ffffff);
    --smoke-inverse-gradient: linear-gradient( #ffffff, #ebebeb);
}